import { Dialog, DialogTitle, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import './CurrencyDialog.scss'

const CurrencyDialog = (props) => {
  const { open, handleClose, selectCurrency } = props;
  
  const handleSelectCurrency = (c) => {
    selectCurrency(c);
    handleClose();
  }

  return (
    <Dialog onClose={handleClose} open={open} className="currency-dialog-main">
      <DialogTitle>Select Currency</DialogTitle>

      <List sx={{ pt: 0 }}>
        {["SGD", "USD", "INR"].map((c, i) => (
          <ListItem key={i} onClick={() => handleSelectCurrency(c)}>
            <ListItemText className="text">{c}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default CurrencyDialog;
