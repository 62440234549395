/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
/* import cookie from "react-cookies"; */
import { apiUrl, fizoheader, UopUrl } from "../../Helpers/Config";
import { GET_UPLOAD_FILES } from "../../../actions";
import addplusimg from "../../../common/images/add-imgplus.svg";

import axios from "axios";
import cookie from "react-cookies";
import backarrow from "../../../common/images/back-arrow-front.svg";
import { GET_CUSTOMER_DETAIL } from "../../../actions";
import SubHeader from "../SubHeader";
import "./AddPhotos.scss";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import CarouselModal from "../CarouselModal/CarouselModal";
import useUnsavedChangesWarning from "../../Help/UnsavedPopup";

class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log("mediaPage", this.props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }

    var coverImage = "";
    var additionalImage = [];
    var additionalImage_moble = [];
    var videoList = [];
    let allowedSection = [];
    let packageID = "";
    let newPackage = true;
    let postedSection = [];
    if (this.props?.location?.state?.packageID !== undefined) {
      packageID = this.props?.location?.state?.packageID;
    }
    if (this.props?.location?.state?.creator_package_id !== undefined) {
      packageID = this.props?.location?.state?.creator_package_id;
    }
    if (this.props?.location?.state !== undefined) {
      coverImage = this.props.location.state.thumbnail || "";
      additionalImage = this.props.location.state.additionalImage || [];
      additionalImage_moble =
        this.props.location.state.additionalImage_moble || [];
      videoList = this.props.location.state.videos || [];
      allowedSection = this.props.location.state.allowedSection;
      newPackage = this.props.location.state.newPackage;
    }

    if (!allowedSection?.includes("add-photos")) {
      this.props.history.push("/myaccount/general-info");
    }
    if (this.props?.location?.state?.postedSection !== undefined) {
      postedSection = this.props?.location?.state?.postedSection;
    }

    this.state = {
      CUserId: CUserId,
      packageID: packageID,
      customerdetail: "",
      isLoading: true,
      airport: [],
      packagecountry: "",
      creator: [],
      coverImage: coverImage,
      coverImage_mobile: coverImage,
      additionalImage: additionalImage,
      additionalImage_moble: additionalImage_moble,
      videoList: videoList,
      additionalImageIndex: "",
      openModal: false,
      carouselData: [],
      type: null,
      errors: {},
      purpose_coverImage: false,
      purpose_additionalImage: false,
      allowedSection: allowedSection,
      newPackage: newPackage,
      postedSection: postedSection,
      isFormEdited: false,
    };
    /*  if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      props.history.push("/");
    } else {
      var params = "customer_id=" + cookie.load("UserId");
      this.props.getCustomerDetail(params);
    } */
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleImageUploadClick = this.handleImageUploadClick.bind(this);
    this.addImageRef = React.createRef();
    this.addImageRef1 = React.createRef();
    this.addImageRef2 = React.createRef();
    this.addImageRef3 = React.createRef();
    this.addImageRef4 = React.createRef();
    this.addImageRef5 = React.createRef();
    this.addVideoRef = React.createRef();
    this.addVideoRef2 = React.createRef();
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    let posted = this.state.postedSection.includes("add-photos");
    if (!this.state.newPackage || posted) {
      console.log("called");
      this.getPackageDetails();
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(this.state, "state", nextProps, "nextProps");
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
    }
    if (
      this.state.purpose_coverImage &&
      nextProps.uploadfiles.web !== undefined
    ) {
      this.setState({
        coverImage: nextProps.uploadfiles.web,
      });
    }
    if (
      this.state.purpose_coverImage &&
      nextProps.uploadfiles.mobile !== undefined
    ) {
      this.setState({
        coverImage_mobile: nextProps.uploadfiles.mobile,
      });
    }

    if (
      this.state.purpose_additionalImage &&
      !this.state.purpose_coverImage &&
      this.state?.purpose_additionalImage !== undefined &&
      this.state?.additionalImage[this.state?.additionalImageIndex] !==
        nextProps.uploadfiles.web
    ) {
      if (this.state.additionalImageIndex !== "") {
        const updatedImageList = [...this.state.additionalImage];
        updatedImageList[this.state.additionalImageIndex] =
          nextProps.uploadfiles.web;
        this.setState({ additionalImage: updatedImageList });
      }
    }
    if (
      this.state.purpose_additionalImage &&
      !this.state.purpose_coverImage &&
      this.state?.additionalImage_moble !== undefined &&
      this.state?.additionalImage_moble[this.state?.additionalImageIndex] !==
        nextProps.uploadfiles.mobile
    ) {
      if (this.state.additionalImageIndex !== null) {
        const updatedImageList_mobile = [...this.state.additionalImage_moble];
        updatedImageList_mobile[this.state.additionalImageIndex] =
          nextProps.uploadfiles.mobile;
        this.setState({ additionalImage_moble: updatedImageList_mobile });
      }
    }
    this.setState({
      purpose_coverImage: false,
      purpose_additionalImage: false,
    });
    console.log(this.state, "after state");
  }

  getPackageDetails = async () => {
    try {
      const { packageID } = this.state;
      const res = await axios.get(
        `${UopUrl}/package?id=${packageID}`,
        fizoheader
      );
      console.log("called 2");
      if (res.data !== "") {
        console.log(res.data, "res.data[0]");
        var gallery_Web = res.data[0].creator_package_gallery_web?.split(",");
        var updat_gallery_web = [];
        gallery_Web.map((item) => {
          if (item.indexOf("https") >= 0) updat_gallery_web.push(item);
        });
        var gallery_mobile =
          res.data[0].creator_package_gallery_mobile?.split(",");
        var updat_gallery_mobile = [];
        gallery_mobile.map((item) => {
          if (item.indexOf("https") >= 0) updat_gallery_mobile.push(item);
        });
        console.log(updat_gallery_web, updat_gallery_mobile, "updated");
        this.setState(
          {
            coverImage: res.data[0].creator_package_thumbnail_web,
            coverImage_mobile: res.data[0].creator_package_thumbnail,
            additionalImage: updat_gallery_web,
            additionalImage_moble: updat_gallery_mobile,
            videoList: res.data[0].creator_package_video?.split(",") || [],
            allowedSection: res.data[0].creator_package_thumbnail
              ? [...this.state.allowedSection, "itinerary-list"]
              : [...this.state.allowedSection],
          },
          () => console.log(this.state.videoList)
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  openPopup() {
    $.magnificPopup.open({
      items: {
        src: "#createpackage",
      },
      type: "inline",
      midClick: true,
    });
  }

  uploadMedia = async () => {
    console.log(this.state.coverImage);
    this.state.additionalImage;
    try {
      const errors = this.validateForm();
      this.setState({ isFormEdited: false });
      if (Object.keys(errors).length > 0) {
        console.log(errors);
        this.setState({ errors });
      } else {
        this.setState({ isLoading: true });
        var formData = new FormData();
        formData.append("creator_package_id", this.state.packageID);
        formData.append("thumbnail_web", this.state.coverImage);
        formData.append("thumbnail_mobile", this.state.coverImage_mobile);
        /* formData.append("additional_images", this.state.additionalImage); */
        formData.append("additional_images_web", this.state.additionalImage);
        formData.append(
          "additional_images_mobile",
          this.state.additionalImage_moble
        );
        /*   this.state.additionalImage?.map((file) => {
          formData.append("additional_images_web", file);
        });
        this.state.additionalImage_moble?.map((file) => {
          formData.append("additional_images_mobile ", file);
        }); */
        this.state.videoList?.map((file) => {
          formData.append("video", file);
        });

        formData.append("creator_id", this.state.CUserId);
        const res = await axios.put(
          `${UopUrl}/package/image`,
          formData,
          fizoheader
        );

        if (res.status === 200) {
          let allowedSection = [...this.state.allowedSection];
          let postedSection = [...this.state.postedSection];
          if (!allowedSection.includes("itinerary-list")) {
            allowedSection.push("itinerary-list");
          }
          if (!postedSection.includes("add-photos")) {
            postedSection.push("add-photos");
          }
          let packageDetails = this.props.location.state;
          packageDetails["allowedSection"] = allowedSection;
          packageDetails["postedSection"] = postedSection;
          packageDetails["additionalImage"] = this.state.additionalImage;
          packageDetails["additionalImage_moble"] =
            this.state.additionalImage_moble;
          packageDetails["thumbnail"] = this.state.coverImage;
          this.setState({ isLoading: false });
          this.props.history.push({
            pathname: "/myaccount/itinerary-list",
            state: packageDetails,
          });
        }
      }
    } catch (err) {
      this.setState({ isLoading: false });
      console.log(err);
    }
  };

  validateForm = () => {
    const { coverImage, additionalImage } = this.state;
    const errors = {};

    if (coverImage.length === 0) {
      errors.coverImage = "cover image required";
    }
    if (additionalImage.length === 0) {
      errors.additionalImage = "minimum 1 image required";
    }

    return errors;
  };

  getImageUrl = async (file) => {
    let formData = new FormData();
    formData.append("file", file);
    const res = await axios.post(
      `${UopUrl}/package/uploadfiles`,
      formData,
      fizoheader
    );
    if (res.data.status === "ok") {
      return res.data.url;
    } else {
      return null;
    }
  };

  handleCoverImage = (event) => {
    try {
      var selectedFile = event.target.files[0];
      if (!selectedFile) return;

      var formData = new FormData();
      formData.append("file", selectedFile);
      this.props.GetUploadFiles(formData);

      var errors = { ...this.state.errors, coverImage: "" };
      this.setState({ isFormEdited: true, errors, purpose_coverImage: true });
    } catch (error) {
      console.error("Error uploading cover image:", error);
      var errors = {
        ...this.state.errors,
        coverImage: "Error uploading cover image.",
      };
      this.setState({ errors });
    }
  };

  handleRemoveAdditionalImage = (ev, index) => {
    ev.stopPropagation();
    try {
      var imageList = [...this.state.additionalImage];
      var imageList_mobile = [...this.state.additionalImage_moble];
      imageList.splice(index, 1);
      imageList_mobile.splice(index, 1);
      this.setState({
        additionalImage: imageList,
        additionalImage_moble: imageList_mobile,
        isFormEdited: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleOpen = (data, type) => {
    this.setState({ openModal: true, carouselData: data, type: type });
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  handleAdditionalImage = async (event, index) => {
    try {
      const selectedFile = event.target.files[0];
      if (!selectedFile) return;

      const formData = new FormData();
      formData.append("file", selectedFile);

      // Assuming GetUploadFiles is an async function and returns the updated image
      const updatedImage = await this.props.GetUploadFiles(formData);

      // Ensure updatedImage is in the expected format (string or object)
      const imageToUpdate =
        typeof updatedImage === "string"
          ? updatedImage
          : JSON.stringify(updatedImage);

      this.setState((prevState) => {
        const imageList = [...prevState.additionalImage];
        const imageList_mobile = [...prevState.additionalImage_moble];

        imageList[index] = imageToUpdate;
        imageList_mobile[index] = imageToUpdate;

        return {
          isFormEdited: true,
          errors: { ...prevState.errors, additionalImage: "" },
          purpose_additionalImage: true,
          additionalImage: imageList,
          additionalImage_moble: imageList_mobile,
          additionalImageIndex: index,
        };
      });
    } catch (error) {
      console.error("Error uploading additional image:", error);
    }
  };

  handleVideo = (event, index) => {
    try {
      var videoList = [...this.state.videoList];
      videoList[index] = event?.target?.files[0];
      videoList = Array.from(videoList, (item) => item || null);
      this.setState({ videoList: videoList, isFormEdited: true });
    } catch (err) {
      console.log(err);
    }
  };

  handleRemoveVideo = (ev, index) => {
    ev.stopPropagation();
    try {
      var videoList = [...this.state.videoList];
      videoList[index] = null;
      this.setState({ videoList: videoList, isFormEdited: true });
    } catch (err) {
      console.log(err);
    }
  };

  handleImageChange(event) {
    const selectedFile = event.target.files[0];
    // Handle the selected file as needed
    console.log("Selected File:", selectedFile);
  }

  handleImageUploadClick(e, imgRef) {
    e.preventDefault();
    imgRef.current.click();
  }

  removeMedia = (index, name) => {
    try {
      var media = this.state[name];
      var updatedMedia = [];
      media.map((item, idx) => {
        if (index !== idx) {
          updatedMedia.push(item);
        }
      });
      this.setState({ [name]: updatedMedia, isFormEdited: true });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { errors, allowedSection } = this.state;
    console.log(this.state, "state values");
    return (
      <>
        {this.state.isLoading === false ? (
          <>
            {this.state.creator !== "" && (
              <section className="main-blue-bg">
                <div className="container-full">
                  <SubHeader
                    allowedSection={allowedSection}
                    triggerAction={this.uploadMedia}
                    stateValues={this.props?.location?.state}
                  />
                  <div className="brochure add-photos-main">
                    <div className="main-cover-img abstract">
                      <div className="abstract-title">
                        <h4>
                          Cover image <em>*</em>
                        </h4>
                        <p>
                          Please add image with min resolution of 1080x1080.
                        </p>
                      </div>
                      <ul>
                        <li>
                          {!this.state.coverImage ||
                          this.state.coverImage === "undefined" ? (
                            <>
                              <input
                                ref={this.addImageRef}
                                className="file-input"
                                type="file"
                                accept="image/*"
                                onChange={this.handleCoverImage}
                              />
                              <a
                                onClick={(e) =>
                                  this.handleImageUploadClick(
                                    e,
                                    this.addImageRef
                                  )
                                }
                              >
                                <figure>
                                  <img src={addplusimg} />
                                  <figcaption> Add image</figcaption>
                                </figure>
                              </a>
                            </>
                          ) : (
                            <div
                              className="selected-img"
                              onClick={() =>
                                this.handleOpen([this.state.coverImage], "img")
                              }
                            >
                              <img
                                src={
                                  typeof this.state.coverImage === "string" &&
                                  this.state.coverImage?.includes("https")
                                    ? this.state.coverImage
                                    : this.state.coverImage instanceof Blob &&
                                      URL.createObjectURL(this.state.coverImage)
                                }
                              />
                              <IconButton
                                onClick={(ev) => {
                                  ev.stopPropagation();
                                  this.setState({ coverImage: "" });
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                          )}
                        </li>
                      </ul>
                      {errors.coverImage && (
                        <p style={{ color: "red" }}>{errors.coverImage}</p>
                      )}
                    </div>
                    <div className="Additional-cover-img abstract">
                      <div className="abstract-title">
                        <h4>
                          Additional image <em>*</em>
                        </h4>
                        <p>
                          Please add upto 5 images with min resolution of
                          1080x1080.
                        </p>
                      </div>
                      <ul>
                        {console.log(
                          this.state.additionalImage,
                          "this.state.additionalImage"
                        )}
                        {[...Array(5)]?.map((item, index) => (
                          <li>
                            {this.state.additionalImage?.length &&
                            this.state.additionalImage[index] ? (
                              <div
                                className="selected-img"
                                onClick={() =>
                                  this.handleOpen(
                                    this.state.additionalImage,
                                    "img"
                                  )
                                }
                              >
                                <img src={this.state?.additionalImage[index]} />
                                <IconButton
                                  onClick={(ev) =>
                                    this.handleRemoveAdditionalImage(ev, index)
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              </div>
                            ) : (
                              <>
                                <input
                                  // ref={this.addImageRef1}
                                  className="file-input"
                                  type="file"
                                  accept="image/*"
                                  onChange={(ev) =>
                                    this.handleAdditionalImage(ev, index)
                                  }
                                />
                                <a>
                                  <figure>
                                    <img src={addplusimg} />
                                    <figcaption> Add image</figcaption>
                                  </figure>
                                </a>
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                      {errors.additionalImage && (
                        <p style={{ color: "red" }}>{errors.additionalImage}</p>
                      )}
                    </div>
                    <div className="main-cover-video abstract">
                      <div className="abstract-title">
                        <h4>Package video {/* <em>*</em> */}</h4>
                        <p>Please add HD videos with aspect ratio 16:4</p>
                      </div>
                      <ul>
                        {[...Array(2)]?.map((item, index) => (
                          <li>
                            {this.state.videoList?.length &&
                            this.state.videoList !== "undefined" &&
                            this.state.videoList[index] ? (
                              <div
                                className="selected-img"
                                onClick={() =>
                                  this.handleOpen(this.state.videoList, "vid")
                                }
                              >
                                <video
                                  src={
                                    typeof this.state.videoList === "string"
                                      ? this.state.videoList
                                      : typeof this.state.videoList[index] ===
                                          "string" &&
                                        this.state.videoList[index]?.includes(
                                          "https"
                                        )
                                      ? this.state.videoList[index]
                                      : URL.createObjectURL(
                                          this.state.videoList[index]
                                        )
                                  }
                                  width="100%"
                                  height="100%"
                                  controls={false}
                                ></video>
                                <IconButton
                                  onClick={(ev) =>
                                    this.handleRemoveVideo(ev, index)
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              </div>
                            ) : (
                              <>
                                <input
                                  // ref={this.addImageRef1}
                                  className="file-input"
                                  type="file"
                                  accept="video/*"
                                  onChange={(ev) => this.handleVideo(ev, index)}
                                />
                                <a>
                                  <figure>
                                    <img src={addplusimg} />
                                    <figcaption> Add video</figcaption>
                                  </figure>
                                </a>
                              </>
                            )}
                            {/* <input
                            ref={this.addVideoRef}
                            className="file-input"
                            type="file"
                            accept="video/*"
                            onChange={this.handleVideo}
                          />
                          <a
                          // onClick={(e) =>
                          //   this.handleImageUploadClick(e, this.addVideoRef)
                          // }
                          >
                            <figure>
                              <img src={addplusimg} />
                              <figcaption>Add video</figcaption>
                            </figure>
                          </a> */}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  {this.state.openModal && (
                    <CarouselModal
                      open={this.state.openModal}
                      handleClose={this.handleClose}
                      data={this.state.carouselData}
                      type={this.state.type}
                    />
                  )}
                </div>
              </section>
            )}
          </>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        <UnsavedChangesWarning
          message="You have unsaved changes. Are you sure you want to leave?"
          isFormEdited={this.state.isFormEdited}
        />
      </>
    );
  }
}

const UnsavedChangesWarning = ({ message, isFormEdited }) => {
  useUnsavedChangesWarning(message, isFormEdited);
  return null;
};

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  var uploadfiles = Array();
  if (Object.keys(state.uploadfiles).length > 0) {
    if (state.uploadfiles[0].status === "ok") {
      uploadfiles = state.uploadfiles[0];
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
    uploadfiles: uploadfiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
    GetUploadFiles: (formPayload) => {
      dispatch({ type: GET_UPLOAD_FILES, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
