/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import { format } from "date-fns";
import ContentLoader from "react-content-loader";
import {
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  apiUrl,
  fizoheader,
  googleMapsKey,
  cookieConfig,
} from "../Helpers/Config";
import {
  showPriceValue,
  checkingDiscount,
  showAlert,
} from "../Helpers/SettingHelper";
import {
  actinfo1,
  heart,
  heartfill,
  car,
  ticketact,
  hotel,
  flight,
  barrow,
  avholder,
  filter,
  cardbg,
  medal,
} from "../Helpers/Images";
import FilterSheet from "./FilterSheet";
import SignupComponent from "../Layout/SignupComponent";
import { Info as InfoIcon } from "@mui/icons-material";
var qs = require("qs");
var discountPack = "252,253,254"; // Testing Packages
var apidate = new Date();
apidate.setDate(apidate.getDate() + 15);
var formattedDate = apidate.toISOString().split("T")[0];
var api_start_date = formattedDate;
const priceFilterOptions = [
  {
    label: "Below S$500",
    value: "500-",
  },
  {
    label: "S$500 - S$1000",
    value: "500-1000",
  },
  {
    label: "S$2000 - S$5000",
    value: "2000-5000",
  },
  {
    label: "Above S$5000",
    value: "-5000",
  },
];
const daysFilter = [
  {
    label: "2 to 5 days",
    value: "2-5",
  },
  {
    label: "6 to 8 days",
    value: "6-8",
  },
  {
    label: "9 to 12 days",
    value: "9-12",
  },
  {
    label: "13 to 15 days",
    value: "13-15",
  },
];

const getMonthYearFormat = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${month}-${year}`;
};

const getNextYearMonths = () => {
  const today = new Date();
  const nextYear = today.getFullYear();

  const nextYearDates = [];
  for (let i = 0; i < 12; i++) {
    const nextMonthDate = new Date(nextYear, today.getMonth() + i, 1);
    const formattedDate = getMonthYearFormat(nextMonthDate);
    nextYearDates.push({
      label: formattedDate.split("-")[0],
      value: formattedDate,
    });
  }

  return nextYearDates;
};

const nextYearMonths = getNextYearMonths();
// console.log(nextYearMonths);

class PromotionalPackage extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    var UserId = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      UserId = cookie.load("UserId");
    }
    var start_date,
      end_date,
      search_days,
      adults,
      child,
      infant,
      source_airport_code,
      location_source,
      source_airport_id,
      destination_airport_code,
      location_destination,
      destination_airport_id,
      destinationCountry,
      tripcount,
      packageType,
      destinationCity = "";
    var searchdata = "";
    if (
      this.props.location.state !== "" &&
      this.props.location.state !== null &&
      this.props.location.state !== undefined
    ) {
      searchdata = this.props.location.state;
      start_date = searchdata.start_date;
      end_date = searchdata.end_date;
      search_days = searchdata.search_days;
      adults = searchdata.adults;
      child = searchdata.child;
      infant = searchdata.infant;
      source_airport_code = searchdata.source_airport_code;
      location_source = searchdata.location_source;
      source_airport_id = searchdata.source_airport_id;
      destination_airport_code = searchdata.destination_airport_code;
      location_destination = searchdata.location_destination;
      destination_airport_id = searchdata.destination_airport_id;
      destinationCountry = searchdata.destinationCountry;
      destinationCity = searchdata.destinationCity;
      tripcount = searchdata.packageType !== "uop" ? searchdata.trip_count : "";
      packageType = searchdata.packageType;
    } else {
      this.props.history.push("/");
    }
    this.state = {
      triggerLogin: false,
      UserId: UserId,
      searchdata: searchdata,
      start_date: start_date,
      end_date: end_date,
      selectedMonth: searchdata?.selectedMonth || "",
      search_days: search_days,
      adults: adults,
      child: child,
      infant: infant,
      source_airport_code: source_airport_code,
      location_source: location_source,
      source_airport_id: source_airport_id,
      destination_airport_code: destination_airport_code,
      location_destination: location_destination,
      destination_airport_id: destination_airport_id,
      destinationCountry: destinationCountry,
      destinationCity: destinationCity,
      display_package: "",
      loadmore: false,
      startPrice: "",
      endPrice: "",
      displayFilter: "",
      category: [],
      noofpackages: "",
      isMobile: window.innerWidth <= 480,
      openFilterSheet: false,
      openSortSheet: false,
      searchResult: [],
      category: ["Couple", "Solo", "Friends", "Family"],
      categorylist: [],
      filterResult: [],
      count: "",
      maxPrice: "",
      sortOption: "",
      wishlist: [],
      wishlistitem: "",
      addedWishlist: [],
      removedWishlist: [],
      locationData: {},
      priceFilter: null,
      tripcount: tripcount,
      trip_day_packages_status: "Yes",
      trid_day_message: "",
      filtermode_searchpackage: false,
      wish: [],
      isFlight: false,
      isActivities: false,
      isVacation: true,
      showMoreMonths: false,
      packageType: packageType,
    };
  }
  componentDidMount() {
    this.loadFilter();
    this.fetchLocationData();
    if (this.state.searchdata !== "") {
      this.setState({ packageLoading: true }, function () {
        this.searchPackage();
      });
    }
    if (this.state.isMobile) {
      $("#freshworks-container").hide();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isMobile) {
      $("#freshworks-container").hide();
    }
    if (
      prevState?.categorylist?.length !== this.state.categorylist?.length ||
      prevState?.priceFilter !== this.state.priceFilter
    ) {
      this.handleApplyFilter();
    }

    if (prevState?.search_days !== this.state.search_days) {
      this.setState({ packageLoading: true }, function () {
        this.searchPackage();
      });
    }
    if (prevState?.tripcount !== this.state.tripcount) {
      this.setState({ packageLoading: true }, function () {
        this.searchPackage();
      });
    }
  }

  componentWillUnmount() {
    if (this.state.isMobile) {
      $("#freshworks-container").show();
    }
  }
  sateValChange = (field, value) => {
    if (field === "proceedtocontinue" && value === "Yes") {
      if (field === "searchpackage") {
        this.setState({ userID: cookie.load("UserId") }, function () {
          this.addToWishlist(this.state.wishlistitem);
        });
      }
    }
    if (field === "isFlight") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: value,
          isActivities: false,
          isVacation: false,
        },
      });
    }
    if (field === "isActivities") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: value,
          isVacation: false,
        },
      });
    }
    if (field === "isVacation") {
      this.props.history.push({
        pathname: "/",
        state: {
          isFlight: false,
          isActivities: false,
          isVacation: value,
        },
      });
    }
  };

  fetchLocationData() {
    axios
      .get(
        apiUrl + "package/getImages?city_name=" + this.state.destinationCity,
        fizoheader
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({
            locationData: res.data.result_set,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  searchPackage() {
    try {
      var postObject = {
        source: "",
        destination: "",
        return_source: "",
        return_destination: "",
        country: "",
        city: "",
        trip_date: api_start_date,
        search_days: "",
        trip_type: "Return",
        no_of_people: JSON.stringify([{ people: "Adult", quantity: 1 }]),
        customer_id: "",
        creator_package_id: discountPack,
      };
      axios
        .post(
          apiUrl + "package/searchLanding",
          qs.stringify(postObject),
          fizoheader
        )
        .then((res) => {
          if (res.data.status === "ok") {
            var packages = res.data.result_set.packages;
            var minPrice = Math.min(
              ...res.data.result_set.packages.map(
                (item) => item.creator_package_price
              )
            );
            var maxPrice = Math.max(
              ...res.data.result_set.packages.map(
                (item) => item.creator_package_price
              )
            );
            this.setState(
              {
                startPrice: minPrice,
                endPrice: maxPrice,
                maxPrice: maxPrice,
              },
              function () {
                this.loadFilter();
              }
            );
            console.log(packages, "homeDisPackages");
            var currentThis = this;
            $(() => {
              $("#rang").ionRangeSlider({
                min: currentThis.state.startPrice,
                max: currentThis.state.endPrice,
                from: currentThis.state.startPrice,
                to: currentThis.state.endPrice,
                type: "double",
                grid_num: 10,
                onFinish: function (data) {
                  currentThis.setState({ packageLoading: true });
                  currentThis.setState(
                    { startPrice: data.from, endPrice: data.to },
                    function () {
                      currentThis.loadFilter();
                      currentThis.displayPackage();
                    }
                  );
                  setTimeout(() => {
                    currentThis.setState({ packageLoading: false });
                  }, 2000);
                },
              });
            });
            this.setState(
              {
                searchResult: res.data.result_set.packages,
                filterResult: res.data.result_set.packages,
              },
              () => {
                this.displayPackage();
                this.handleApplyFilter();
                this.handleClearAll();
              }
            );
          } else {
            showAlert("Error", "No packages available");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            /*         this.setState({ packageLoading: false }); */
          }
        })
        .catch((err) => {
          showAlert("Error", "Something went wrong, please try again later");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        });
    } catch (err) {
      console.log(err);
    }
  }

  displayPackage() {
    var display_package = "";
    if (this.state.isMobile) {
      if (this.state.searchResult && this.state.searchResult.length > 0) {
        display_package =
          this.state.searchResult.length > 0
            ? this.state.filterResult.map((item, index) => {
                var discount = checkingDiscount(
                  item.discount,
                  item.creator_package_price
                );
                var displyAmount = item.creator_package_price;
                if (discount !== "") {
                  displyAmount =
                    parseFloat(item.creator_package_price) -
                    parseFloat(discount.amount);
                }
                var package_vacation_included = [];
                if (item?.creator_package_source === "uop") {
                  package_vacation_included = item?.creator_package_included
                    ? item?.creator_package_included.split(",")
                    : [];
                }
                console.log(
                  item?.creator_package_included,
                  package_vacation_included,
                  "package_vacation_included"
                );
                return (
                  <li key={index}>
                    <div
                      className="product-list-parent"
                      // onClick={
                      //   this.state.isMobile && this.viewPackage.bind(this, item)
                      // }
                      onClick={this.viewPackage.bind(this, item)}
                    >
                      <div className="product-img">
                        <a
                          href={void 0}
                          className="like"
                          onClick={() => this.addToWishlist(item)}
                        >
                          {this.state?.wish[0]?.includes(
                            item?.creator_package_id
                          ) ? (
                            <img src={heartfill} alt="Filled Heart" />
                          ) : (
                            <img src={heart} alt="Empty Heart" />
                          )}
                        </a>
                        <a
                          className="pt-img"
                          href={void 0}
                          onClick={this.viewPackage.bind(this, item)}
                        >
                          <img
                            src={
                              item.creator_package_thumbnail !== "" &&
                              item.creator_package_thumbnail !== null
                                ? item.creator_package_thumbnail
                                : actinfo1
                            }
                            alt="actinfo"
                            loading="lazy"
                          />
                        </a>
                        <div className="ptd-day">
                          {" "}
                          {item.creator_package_total_days} Days
                        </div>
                        {discount !== "" && (
                          <div className="package-discount">
                            {showPriceValue(discount.amount)} Off
                          </div>
                        )}
                      </div>
                      <div className="product-description">
                        <div className="tags-row">
                          <div className="new-tags">
                            {item.creator_package_travelling && (
                              <div className="c-tags">
                                {item.creator_package_travelling}
                              </div>
                            )}
                            {item.category_title && (
                              <div className="e-tags">
                                {item.category_title}
                              </div>
                            )}
                          </div>
                          <div className="days-col">
                            {item.creator_package_total_days} Days,{" "}
                            {item.creator_package_total_days - 1} Nights
                          </div>
                        </div>
                        <div className="product-description-title">
                          <div className="title-col">
                            <h5>
                              <a
                                href={void 0}
                                onClick={this.viewPackage.bind(this, item)}
                              >
                                {item.creator_package_name}
                              </a>{" "}
                            </h5>
                          </div>
                          {item.creator_package_rating !== "" &&
                            item.creator_package_rating !== null && (
                              <div className="review-col pdr-lhs">
                                <span>
                                  <strong>{item.creator_package_rating}</strong>{" "}
                                  ({item.creator_package_total_rating}) Reviews
                                </span>
                                <div className="star-rating">
                                  {this.loadRatingstar(
                                    item.creator_package_id === "382"
                                      ? "4"
                                      : item.creator_package_rating
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="product-created-content">
                          <div className="created-av">
                            <img
                              src={
                                item.creator_image !== "" &&
                                item.creator_image !== null
                                  ? item.creator_image
                                  : avholder
                              }
                              alt={item.creator_image}
                            />
                          </div>
                          <div className="created-av-title">
                            <h4>
                              <span>
                                {item?.creator_package_source === "uop"
                                  ? "Operated by"
                                  : "Created by"}
                              </span>

                              {item.creator_handle_name}
                            </h4>
                            <div className="creator-badge">
                              <span>
                                <img src={medal} alt="Medal" />
                              </span>{" "}
                              <strong>
                                Level <em>01</em>
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="sites-content">
                          {item?.creator_package_source === "uop" ? (
                            <ul>
                              {package_vacation_included?.map((item, index) => (
                                <li key={index}>
                                  <figure>
                                    <img src={flight} alt="flight" />{" "}
                                  </figure>
                                  <figcaption>{item}</figcaption>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <ul>
                              <li>
                                <figure>
                                  <img src={flight} alt="flight" />{" "}
                                </figure>
                                <figcaption>Flight (add-on)</figcaption>
                              </li>

                              {item?.stay?.length > 0 &&
                                item?.creator_package_included &&
                                item?.creator_package_included
                                  .split(",")
                                  .includes("Stay") && (
                                  <li>
                                    <figure>
                                      <img src={flight} alt="flight" />{" "}
                                    </figure>
                                    <figcaption>Stay</figcaption>
                                  </li>
                                )}
                              {item.total_activities > 0 &&
                                item.creator_package_included &&
                                item.creator_package_included
                                  .split(",")
                                  .includes("Things to do") && (
                                  <li>
                                    <figure>
                                      <img src={flight} alt="flight" />{" "}
                                    </figure>
                                    <figcaption>
                                      {item.total_activities} Things to do
                                    </figcaption>
                                  </li>
                                )}

                              <li>
                                <figure>
                                  <img src={flight} alt="flight" />{" "}
                                </figure>
                                <figcaption>Transfer (add-on)</figcaption>
                              </li>

                              {item.creator_package_included &&
                                item.creator_package_included
                                  .split(",")
                                  .includes("Food and beverage") && (
                                  <li>
                                    <figure>
                                      <img src={flight} alt="flight" />{" "}
                                    </figure>
                                    <figcaption>Food and beverage</figcaption>
                                  </li>
                                )}
                            </ul>
                          )}
                        </div>
                        <div className="footer-main">
                          <div className="product-description-review">
                            <div className="pdr-lhs">
                              <div
                                className={`package-source ${
                                  item?.creator_package_source === "uop"
                                    ? "bg-clr1"
                                    : "bg-clr"
                                }`}
                              >
                                {item?.creator_package_source === "uop"
                                  ? "Curated"
                                  : "Customisable"}
                              </div>
                            </div>
                            <div className="pdr-rhs">
                              {discount !== "" ? (
                                <>
                                  <span className="package-original-amt">
                                    {showPriceValue(item.creator_package_price)}
                                  </span>
                                </>
                              ) : (
                                <em>Starts from</em>
                              )}
                              <strong>
                                {showPriceValue(item.creator_package_price)}
                              </strong>{" "}
                            </div>
                          </div>
                          <div className="cp-main">
                            <Button
                              variant="contained"
                              onClick={this.viewPackage.bind(this, item)}
                            >
                              Book now
                            </Button>
                          </div>
                        </div>
                        {/* <div className="product-description-review">
                          <div className="pdr-rhs">
                            <div className="new-tags">
                              <div className="cp-tags">
                                {item.next_availability_slot}
                              </div>
                            </div>
                          </div>
                          <div className="pdr-lhs">
                            {item.creator_package_rating !== "" &&
                              item.creator_package_rating !== null && (
                                <span>
                                  <strong>{item.creator_package_rating}</strong>{" "}
                                  ({item.creator_package_total_rating}) Reviews
                                </span>
                              )}
                            <div className="star-rating">
                              {this.loadRatingstar(item.creator_package_rating)}
                            </div>
                          </div>
                          {item?.creator_package_source === "uop" && (
                            <div className="pdr-rhs">
                              {discount !== "" ? (
                                <>
                                  <span className="package-original-amt">
                                    {showPriceValue(item.creator_package_price)}
                                  </span>
                                </>
                              ) : (
                                <em>Starts from</em>
                              )}
                              <strong>
                                {showPriceValue(item.creator_package_price)}
                                <span> Per Adult</span>
                              </strong>{" "}
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </li>
                );
              })
            : "NO PACKAGES FOUND";
      }
    } else {
      if (this.state.filterResult && this.state.filterResult.length > 0) {
        display_package =
          this.state.filterResult.length > 0 &&
          this.state.filterResult.map((item, index) => {
            var discount = checkingDiscount(
              item.discount,
              item.creator_package_price
            );
            var displyAmount = item.creator_package_price;
            if (discount !== "") {
              displyAmount =
                parseFloat(item.creator_package_price) -
                parseFloat(discount.amount);
            }
            var package_included = [];
            if (item?.creator_package_source === "uop") {
              package_included = item?.creator_package_included
                ? item?.creator_package_included.split(",")
                : [];
            }

            return (
              <li key={index}>
                <div
                  className="product-list-parent"
                  // onClick={
                  //   this.state.isMobile && this.viewPackage.bind(this, item)
                  // }
                  /*     onClick={this.viewPackage.bind(this, item)} */
                  /* onClick={this.viewPackage.bind(this, item)}*/
                >
                  <div className="product-img">
                    <a
                      className="pt-img"
                      href={void 0}
                      onClick={this.viewPackage.bind(this, item)}
                    >
                      <img
                        src={
                          item.creator_package_thumbnail !== "" &&
                          item.creator_package_thumbnail !== null
                            ? item.creator_package_thumbnail
                            : actinfo1
                        }
                        alt="actinfo"
                        loading="lazy"
                      />
                    </a>
                    <div className="ptd-day">
                      {" "}
                      {item.creator_package_total_days} Days
                    </div>
                    {discount !== "" && (
                      <div className="package-discount">
                        {showPriceValue(discount.amount)} Off
                      </div>
                    )}
                  </div>
                  <div className="product-description">
                    <div className="new-tags">
                      {item.creator_package_travelling !== "" &&
                        item.creator_package_travelling !== null && (
                          <div className="c-tags">
                            {item.creator_package_travelling}
                          </div>
                        )}
                      {item.category_title !== "" &&
                        item.category_title !== null && (
                          <div className="e-tags">{item.category_title}</div>
                        )}
                      {item?.creator_package_source === "uop" && (
                        <div className="cp-tags">Curated package</div>
                      )}
                    </div>
                    <a
                      href={void 0}
                      className="like"
                      onClick={() => this.addToWishlist(item)}
                    >
                      {this.state?.wish[0]?.includes(
                        item?.creator_package_id
                      ) ? (
                        <img src={heartfill} alt="Filled Heart" />
                      ) : (
                        <img src={heart} alt="Empty Heart" />
                      )}
                    </a>
                    <div className="product-description-title">
                      <h5>
                        <a
                          href={void 0}
                          onClick={this.viewPackage.bind(this, item)}
                        >
                          {item.creator_package_name}
                        </a>{" "}
                      </h5>
                    </div>
                    <div className="product-created-content">
                      <div className="created-av">
                        <img
                          src={
                            item.creator_image !== "" &&
                            item.creator_image !== null
                              ? item.creator_image
                              : avholder
                          }
                          alt={item.creator_image}
                        />
                      </div>
                      <div className="created-av-title">
                        <h4>
                          <span>
                            {item?.creator_package_source === "uop"
                              ? "Operated by"
                              : "Created by"}
                          </span>

                          {item.creator_handle_name}
                        </h4>
                        <div className="creator-badge">
                          <span>
                            <img src={medal} alt="Medal" />
                          </span>{" "}
                          <strong>
                            Level <em>01</em>
                          </strong>
                        </div>
                      </div>
                    </div>
                    <div className="m-row">
                      <div className="col1">
                        <div className="sites-content">
                          {item?.creator_package_source === "uop" ? (
                            <ul>
                              {package_included.map((incItem, incIndex) => {
                                return (
                                  <li className="sc1" key={incIndex}>
                                    <figure>
                                      <img src={flight} alt={incItem} />
                                    </figure>
                                    <figcaption>{incItem}</figcaption>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            <ul>
                              <li className="sc1">
                                <figure>
                                  <img src={flight} alt="flight" />
                                </figure>
                                <figcaption>Flight (add-on)</figcaption>
                              </li>
                              <li className="sc4">
                                <figure>
                                  <img src={car} alt="car" />
                                </figure>
                                <figcaption>Transfer (add-on)</figcaption>
                              </li>
                              {item.stay.length > 0 && (
                                <li className="sc2">
                                  <figure>
                                    <img src={hotel} alt="hotel" />
                                  </figure>
                                  <figcaption>
                                    {item.stay.length} Stay
                                  </figcaption>
                                </li>
                              )}

                              {item.total_activities > 0 && (
                                <li className="sc3">
                                  <figure>
                                    <img src={ticketact} alt="ticketact" />
                                  </figure>
                                  <figcaption>
                                    {item.total_activities} Things to do
                                  </figcaption>
                                </li>
                              )}
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="col3"></div>
                      <div className="col2">
                        {discount !== "" ? (
                          <div>
                            <span className="package-discount">
                              {showPriceValue(discount.amount)} Off
                            </span>
                          </div>
                        ) : null}
                        <>
                          <div className="price-row">
                            <em>Starts from</em>
                            {discount !== "" ? (
                              <div className="package-original-amt">
                                {showPriceValue(item.creator_package_price)}
                              </div>
                            ) : null}
                            <strong>{showPriceValue(displyAmount)}</strong>{" "}
                          </div>
                        </>
                      </div>
                    </div>

                    <div className="product-description-review">
                      <div className="pdr-rhs">
                        {item?.next_availability_slot && (
                          <div className="new-tags">
                            <div className="cp-tags">
                              Next Available slot: {item.next_availability_slot}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="pdr-lhs">
                        {item.creator_package_rating !== "" &&
                          item.creator_package_rating !== null && (
                            <span>
                              <strong>{item.creator_package_rating}</strong> (
                              {item.creator_package_total_rating}) Reviews
                            </span>
                          )}
                        <div className="star-rating">
                          {this.loadRatingstar(
                            item.creator_package_id === "382"
                              ? "4"
                              : item.creator_package_rating
                          )}
                        </div>
                      </div>
                      <div className="pdr-rhs">
                        <a
                          href={void 0}
                          className="button button-fill"
                          onClick={this.viewPackage.bind(this, item)}
                        >
                          View package
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          });
      }
    }

    this.setState({ display_package: display_package }, function () {
      setTimeout(() => {
        this.setState({ packageLoading: false });
      }, 2000);
    });
  }
  handleChange(type, event) {
    if (type === "category") {
      var categorylist = [...this.state.categorylist];
      if (event?.target) {
        if (event.target.checked === true) {
          categorylist.push(event.target.value);
        } else {
          var index = categorylist.indexOf(event.target.value);
          if (index > -1) {
            categorylist.splice(index, 1);
          }
        }
      } else {
        var index = categorylist.indexOf(event);
        if (index > -1) {
          categorylist.splice(index, 1);
        } else {
          categorylist.push(event);
        }
      }
      this.setState({ categorylist: categorylist });
    } else if (type === "sortOption") {
      this.setState({ sortOption: event.target.value }, () => {
        this.handleApplyFilter();
      });
    }
  }

  loadRatingstar(points) {
    if (points !== "" && points !== null) {
      var splitrating = points.split(".");
      return [...Array(5)].map((star, index) => {
        var currentRate = parseInt(index) + 1;
        if (parseFloat(points) >= currentRate) {
          if (
            parseInt(splitrating[0]) === parseInt(currentRate) &&
            parseInt(splitrating[1]) === 5
          ) {
            return (
              <i
                className="fa fa-star-half-o"
                aria-hidden="true"
                key={index}
              ></i>
            );
          } else {
            return (
              <i className="fa fa-star" aria-hidden="true" key={index}></i>
            );
          }
        }
      });
    }
  }

  viewPackage(item) {
    var searchdata = this.state.searchdata;
    searchdata["total_activities"] = item.total_activities;
    searchdata["creator_package_source"] = item.creator_package_source;
    var endDate = new Date(searchdata.start_date);
    searchdata["end_date"] = new Date(
      endDate.setDate(
        parseInt(endDate.getDate()) +
          (parseInt(item.creator_package_total_days) + 1)
      )
    );
    searchdata["checkinTime"] =
      item.hotel_check_in_time !== "" &&
      typeof item.hotel_check_in_time !== undefined &&
      typeof item.hotel_check_in_time !== "undefined"
        ? item.hotel_check_in_time
        : "";
    var postObject = {
      creator_id: item.creator_id,
      package_id: item.creator_package_id,
    };

    axios
      .post(
        apiUrl + "package/packageClick",
        qs.stringify(postObject),
        fizoheader
      )
      .then((res) => {
        if (res.data.status === "ok") {
          cookie.remove("flightStatus", cookieConfig);
          var packagedetails = {
            searchdata: searchdata,
            hotel_check_in_time: item.hotel_check_in_time,
            creator_package_total_days: item.creator_package_total_days,
          };
          this.props.history.push({
            pathname: "/search-package/" + item.creator_package_slug,
            state: packagedetails,
          });
        } else {
          console.log("ClickApi response", res.data);
        }
      });
  }

  createOwnPackage() {
    // var activities = [
    //   { day: 1, emptyactivity: "Yes", activities: [] },
    //   { day: 2, emptyactivity: "Yes", activities: [] },
    // ];
    var date = this.state.searchdata.start_date;
    const startDate = new Date(date);

    const date2String = this.state.searchdata.search_days;

    let daysToAdd;

    if (date2String === "2-5") {
      daysToAdd = 3;
    } else if (date2String === "6-8") {
      daysToAdd = 6;
    } else if (date2String === "9-12") {
      daysToAdd = 10;
    } else if (date2String === "13-15") {
      daysToAdd = 13;
    }

    const dates = [];

    for (let i = 1; i <= daysToAdd; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(startDate.getDate() + i);
      dates.push(currentDate.toDateString());
    }

    function formatDate(inputDate) {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
      const day = date.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    }

    var activities = dates.map((date, index) => ({
      day: parseInt(index) + 1,
      date: formatDate(date),
      emptyactivity: "Yes",
      activities: ["", "", ""],
    }));
    var searchdata = this.state.searchdata;

    if (searchdata.end_date !== "") {
      var endDate = new Date(searchdata.end_date);
    } else {
      var end_date = new Date(searchdata.start_date);
      var addedDay = end_date.setDate(
        parseInt(end_date.getDate()) + parseInt(daysToAdd + 1)
      );
      var endDate = new Date(addedDay);
    }

    searchdata["end_date"] = endDate;
    searchdata["checkinTime"] = "";
    var packagedetails = {
      searchdata: searchdata,
      activities: activities,
    };

    this.props.history.push({
      pathname: "/search-package/create-own-package",
      state: packagedetails,
    });
  }
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
  toggleAccordion(event) {
    const accordionHeader = event.currentTarget;
    accordionHeader.classList.toggle("active");
    const accordionContent = accordionHeader.nextElementSibling;
    accordionContent.style.display =
      accordionContent.style.display === "none" ? "block" : "none";
  }
  loadFilter() {
    var displayfilter = (
      <div className="package-top-filters">
        <div className="ptf-options">
          <ul>
            {/* <li>
              <a href={void 0} onClick={this.openfilter.bind(this)}>
                <div className="active-options">
                  {this.state.count ? this.state.count : 0}
                </div>{" "}
                <span>Category</span>
              </a>
            </li> */}
            <li>
              <a href={void 0} onClick={this.openfilter.bind(this)}>
                {this.state.count > 0 && (
                  <div className="active-options">{this.state.count}</div>
                )}
                <span>
                  Filters <img src={filter} alt="filter" />
                </span>
              </a>
            </li>
          </ul>
        </div>
        {this.state.isMobile && (
          <div className="ptf-options">
            <ul>
              <li>
                <a
                  href={void 0}
                  onClick={() => this.setState({ openSortSheet: true })}
                >
                  <span>Sort By</span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    );
    this.setState({
      displayFilter: displayfilter,
    });
  }
  applyFilter(item) {
    var packages = false;
    // if (
    //   Math.round(this.state.startPrice) > 0 &&
    //   Math.round(item.creator_package_price) < Math.round(this.state.startPrice)
    // ) {
    //   packages = false;
    // }
    // if (
    //   Math.round(this.state.endPrice) > 0 &&
    //   Math.round(item.creator_package_price) > Math.round(this.state.endPrice)
    // ) {
    //   packages = false;
    // }
    if (this.state.priceFilter) {
      if (
        Number(this.state.priceFilter?.split("-")[0]) >=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[1]) === 0
      ) {
        packages = true;
      }
      if (
        Number(this.state.priceFilter?.split("-")[1]) <=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[0]) === 0
      ) {
        packages = true;
      }
      if (
        Number(this.state.priceFilter?.split("-")[0]) <=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[1]) >=
          Math.round(item?.creator_package_price) &&
        Number(this.state.priceFilter?.split("-")[0]) !== 0 &&
        Number(this.state.priceFilter?.split("-")[1]) !== 0
      ) {
        packages = true;
      }
    } else {
      packages = true;
    }
    var category = true;
    if (this.state.categorylist.length > 0) {
      if (
        this.state.categorylist.indexOf(
          item.creator_package_travelling.toString()
        ) === -1
      ) {
        category = false;
      }
    }
    if (packages && category) {
      return true;
    } else {
      return false;
    }
  }
  handleApplyFilter() {
    this.setState({ packageLoading: true });

    const filteredPackages = this.state.searchResult.filter((item) => {
      return this.applyFilter(item);
    });

    const sortedPackages = [...filteredPackages];

    if (this.state.sortOption === "lowToHigh") {
      sortedPackages.sort(
        (a, b) => a.creator_package_price - b.creator_package_price
      );
    } else if (this.state.sortOption === "highToLow") {
      sortedPackages.sort(
        (a, b) => b.creator_package_price - a.creator_package_price
      );
    }

    var minPrice = Math.min(
      ...sortedPackages.map((item) => item.creator_package_price)
    );
    var maxPrice = Math.max(
      ...sortedPackages.map((item) => item.creator_package_price)
    );
    var count = 0;
    if (this.state.categorylist.length > 0) {
      count++;
    }
    if (this.state.startPrice !== 0) {
      if (
        Math.round(this.state.startPrice) !== Math.round(minPrice) &&
        Math.round(this.state.endPrice) !== Math.round(maxPrice)
      ) {
        count++;
      }
    }

    this.setState({ count: count }, function () {
      this.loadFilter();
    });

    this.setState({ filterResult: sortedPackages }, function () {
      this.displayPackage();
    });
    $.magnificPopup.close({
      items: {
        src: "#filter-stick-popup",
      },
      type: "inline",
    });
  }

  openPopup(popupID) {
    $.magnificPopup.open({
      items: {
        src: popupID,
      },
      type: "inline",
      closeOnBgClick: false,
      midClick: true,
      mainClass: this.state.isMobile ? "package-info" : "",
      callbacks: {
        open: function () {
          $("body").css("overflow", "hidden");
        },
        close: function () {
          $("body").css("overflow", "");
        },
      },
    });
  }

  openfilter() {
    if (this.state.isMobile) {
      this.setState({ openFilterSheet: true });
    } else {
      $.magnificPopup.open({
        items: {
          src: "#filter-stick-popup",
        },
        type: "inline",
      });
    }
  }
  handleDaysFilter(val) {
    this.setState({ filtermode_searchpackage: true });
    this.setState({ search_days: val, packageLoading: true });
  }
  handlePriceFilter(val) {
    this.setState({ priceFilter: this.state.priceFilter === val ? null : val });
  }
  handleClearAll() {
    const { searchResult } = this.state;
    const minPrice = Math.min(
      ...searchResult.map((item) => item.creator_package_price)
    );
    const maxPrice = Math.max(
      ...searchResult.map((item) => item.creator_package_price)
    );
    this.setState(
      {
        startPrice: minPrice,
        endPrice: maxPrice,
        categorylist: [],
        count: "",
        filterResult: this.state.searchResult,
      },
      function () {
        this.loadFilter();
        this.displayPackage();
        const sliderElement = $("#rang");
        sliderElement.data("ionRangeSlider")?.update({
          from: minPrice,
          to: maxPrice,
        });
      }
    );
    const checkboxes = document.querySelectorAll(".tag_filter");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    try {
      const slider = $("#rang").data("ionRangeSlider");
      slider.update({
        from: minPrice,
        to: maxPrice,
      });
    } catch (error) {
      console.error("Error updating ionRangeSlider:", error);
    }
  }

  addToWishlist = (item) => {
    if (this.state.UserId !== "") {
      console.log("function addwishlist");
      var wishlist = [...this.state.wish];
      var itemIndex = wishlist[0].indexOf(item.creator_package_id);
      if (itemIndex === -1) {
        wishlist[0].push(item.creator_package_id);
      }
      if (itemIndex !== -1) {
        console.log("splice");
        wishlist[0].splice(itemIndex, 1);
      }
      var addedWishlist = [...this.state.addedWishlist];
      var formData = new FormData();
      formData.append("customer_id", cookie.load("UserId"));
      formData.append("package_id", item.creator_package_id);
      axios
        .post(apiUrl + "package/addToPackageWishlist", formData, fizoheader)
        .then((response) => {
          if (response.data.status === "ok") {
            var index = addedWishlist.findIndex(
              (removedItem) =>
                removedItem.creator_package_id === item.creator_package_id
            );
            addedWishlist.splice(index, 1);
            this.setState(
              {
                addedWishlist: addedWishlist,
                wish: wishlist,
              },
              function () {
                this.displayPackage();
                // this.addwishlist(item);
              }
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      if (cookie.load("UserId") !== "") {
        console.log(item, "clicked");
        this.setState({ wishlistitem: item }, function () {
          $.magnificPopup.open({
            items: {
              src: "#customer-login-popup",
            },
            type: "inline",
            closeOnBgClick: false,
          });
        });
      }
    }
  };

  /*  addToWishlist = (item) => {
      if (this.state.UserId !== "") {
        var isItemInWishlist = this.state.wishlist.some(
          (wishlistItem) =>
            wishlistItem.creator_package_id === item.creator_package_id
        );
        if (!isItemInWishlist) {
          var formData = new FormData();
          formData.append("customer_id", cookie.load("UserId"));
          formData.append("package_id", item.creator_package_id);
          axios
            .post(apiUrl + "package/addToPackageWishlist", formData, fizoheader)
            .then((response) => {
              if (response.data.status === "ok") {
                this.setState(
                  (prevState) => ({
                    wishlist: [...prevState.wishlist, item],
                  }),
                  () => {
                    this.displayPackage();
                  }
                );
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          this.setState((prevState) => ({
            wishlist: prevState.wishlist.filter(
              (wishlistItem) =>
                wishlistItem.creator_package_id !== item.creator_package_id
            ),
          }));
          var formData = new FormData();
          formData.append("customer_id", cookie.load("UserId"));
          formData.append("package_id", item.creator_package_id);
          axios
            .post(apiUrl + "package/addToPackageWishlist", formData, fizoheader)
            .then((response) => {
              if (response.data.status === "ok") {
                this.setState(
                  (prevState) => ({
                    wishlist: prevState.wishlist.filter(
                      (wishlistItem) =>
                        wishlistItem.creator_package_id !==
                        item.creator_package_id
                    ),
                  }),
                  () => {
                    this.displayPackage();
                  }
                );
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        this.setState({ wishlistitem: item }, function () {
          $.magnificPopup.open({
            items: {
              src: "#customer-login-popup",
            },
            type: "inline",
            closeOnBgClick: false,
          });
        });
      }
    }; */
  /*   addToWishlist = (item) => {
        console.log("sdsdsd");
        const isItemInWishlist = item.wishlist === "yes" ? "yes" : "no"
        if (this.state.UserId !== "") {
          var formData = new FormData();
          formData.append("customer_id", cookie.load("UserId"));
          formData.append("package_id", item.creator_package_id);
          axios
            .post(apiUrl + "package/addToPackageWishlist", formData)
            .then((response) => {
              if (response.data.status === "ok") {
                const updatedItem = { ...item, wishlist: isItemInWishlist ? "no" : "yes" };
                var filterResult = this.state.filterResult.map((resultItem) =>
                  resultItem.creator_package_id === item.creator_package_id
                    ? updatedItem
                    : resultItem
                )
                this.setState({
                  filterResult: filterResult
                },
                  () => {
                    this.displayPackage();
                    this.searchPackage();
                  }, function () { this.state.filterResult.map(item => console.log(item.wishlist)), "wishlisted" },
                );
                console.log("yes")
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          this.setState(function () {
            $.magnificPopup.open({
              items: {
                src: "#customer-login-popup",
              },
              type: "inline",
              closeOnBgClick: false,
            });
          });
        }
        const updatedItem = { ...item, wishlist: isItemInWishlist ? "no" : "yes" };
        var filterResult = this.state.filterResult.map((resultItem) =>
          resultItem.creator_package_id === item.creator_package_id
            ? updatedItem
            : resultItem
        )
        this.setState({
          filterResult: filterResult
        })
      }; */

  addwishlist(item, index) {
    // Ensure this.state.wish is initialized as an array
    if (!Array.isArray(this.state.wish)) {
      this.state.wish = [];
    }

    var wishlist = this.state.wish;

    console.log(typeof this.state.wish);
    var itemIndex = wishlist.indexOf(item.creator_package_id);
    if (itemIndex < 0) {
      console.log("push");
      wishlist.push(item.creator_package_id);
    } else {
      console.log("splice");
      wishlist.splice(itemIndex, 1);
    }
    console.log(wishlist);

    this.setState({
      wish: wishlist,
    });
  }

  monthFilter = (month) => {
    this.setState({ packageLoading: true, selectedMonth: month }, () =>
      this.searchPackage()
    );
  };

  render() {
    const { packageType } = this.state;
    const iframeSrc = `https://www.google.com/maps/embed/v1/place?key=${googleMapsKey}&q=${encodeURIComponent(
      this.state.destinationCity
    )}`;
    return (
      <>
        <Header
          {...this.props}
          sateValChange={this.sateValChange}
          triggerLogin={this.state.triggerLogin}
        />
        <div className="search-nav">
          <div className="container"></div>
        </div>

        {!this.state.isMobile && (
          <section className="map-banner-parent">
            <div className="banner-map">
              <div className="bm-lhs">
                <img src={this.state.locationData?.large_image} alt="" />
                <div className="pl-name">
                  <h2>{this.state?.destinationCity}</h2>
                  <p>{this.state?.locationData?.description}</p>
                </div>
              </div>
              {!this.state.isMobile && (
                <div className="bm-rhs">
                  <div className="bmr-top">
                    <img src={this.state.locationData?.small_image} alt="" />
                  </div>
                  <div className="bmr-btm">
                    <iframe
                      src={iframeSrc}
                      width="100%"
                      height="100%"
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              )}
            </div>
          </section>
        )}

        <section className="innersection">
          <div className="container">
            <div className="package-av-list">
              <div className="package-top-filters color-head-merge">
                {this.state.isMobile && (
                  <h2 className="color-header">
                    Your holiday packages for{" "}
                    <span>
                      {this.state.searchdata !== ""
                        ? this.state.searchdata.destinationCity
                        : ""}
                    </span>
                  </h2>
                )}
                {!this.state.isMobile && (
                  <div className="category-filter">
                    {this.state.category?.map((item, index) => (
                      <div
                        className={`category-box ${
                          this.state.categorylist?.includes(item)
                            ? "active"
                            : ""
                        }`}
                        key={index}
                        onClick={() => this.handleChange("category", item)}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}
                {!this.state.isMobile && (
                  <div className="package-top-btn">
                    <a
                      className="button button-fill"
                      href={void 0}
                      onClick={this.createOwnPackage.bind(this)}
                    >
                      Create Your Own Package
                    </a>
                  </div>
                )}
              </div>
              {this.state.isMobile && this.state.displayFilter}

              <div
                className={`${this.state.isMobile ? "" : "package-list-row"}`}
              >
                {!this.state.isMobile && (
                  <div className="left-col">
                    <div className="filter-main-sticky">
                      <div className="filter-title">Filters</div>
                      <div className="filter-head">Days</div>
                      <div className="fliter-option-row">
                        <FormGroup>
                          {daysFilter?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": { color: "#4258BF" },
                                  }}
                                  checked={
                                    this.state.search_days === item?.value
                                  }
                                  onChange={() =>
                                    this.handleDaysFilter(item?.value)
                                  }
                                />
                              }
                              label={item?.label}
                            />
                          ))}
                        </FormGroup>
                      </div>
                      <br />
                      {packageType === "uop" && (
                        <>
                          <div className="filter-head">
                            Months{" "}
                            <span
                              onClick={() =>
                                this.setState({
                                  showMoreMonths: !this.state.showMoreMonths,
                                })
                              }
                              className="showMoreBtn"
                            >
                              {this.state.showMoreMonths
                                ? "Show less"
                                : "Show more"}
                            </span>
                          </div>
                          <div className="fliter-option-row">
                            <FormGroup>
                              {nextYearMonths?.map((item, index) => {
                                return (!this.state.showMoreMonths &&
                                  index < 5) ||
                                  this.state.showMoreMonths ? (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            color: "#4258BF",
                                          },
                                        }}
                                        checked={
                                          this.state.selectedMonth ===
                                          item?.value
                                        }
                                        onChange={() =>
                                          this.monthFilter(item?.value)
                                        }
                                      />
                                    }
                                    label={item?.label}
                                  />
                                ) : null;
                              })}
                            </FormGroup>
                          </div>
                          <br />
                        </>
                      )}
                      <div className="filter-head">Price</div>
                      <div className="fliter-option-row">
                        <FormGroup>
                          {priceFilterOptions?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  sx={{
                                    "& .MuiSvgIcon-root": { color: "#4258BF" },
                                  }}
                                  checked={
                                    this.state.priceFilter === item?.value
                                  }
                                  onChange={() =>
                                    this.handlePriceFilter(item?.value)
                                  }
                                />
                              }
                              label={item?.label}
                            />
                          ))}
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                )}
                <div className={`${this.state.isMobile ? "col" : "right-col"}`}>
                  {this.state.packageLoading === false && (
                    <div className="showing-header">
                      <span className="av-result">
                        {this.state.filterResult.length} packages available
                      </span>
                      {/* <a href={void 0}>
                      Sort by <img src={barrow} alt="" />
                    </a> */}
                      {!this.state.isMobile && (
                        <div className="filter-row-new">
                          <h5>Sort By</h5>
                          <div>
                            <select
                              value={this.state.sortOption}
                              onChange={this.handleChange.bind(
                                this,
                                "sortOption"
                              )}
                            >
                              <option value="">None</option>
                              <option value="lowToHigh">
                                Price: Low to High
                              </option>
                              <option value="highToLow">
                                Price: High to Low
                              </option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.packageLoading === true ? (
                    <div className="search-loader">
                      {Array(1, 2, 3, 4).map((item, index) => {
                        return (
                          <ContentLoader
                            viewBox={`0 0 ${
                              this.state.isMobile ? "380" : "100%"
                            } 280`}
                            height={280}
                            width={`${this.state.isMobile ? "380" : "100%"}`}
                            speed={3}
                            key={index}
                          >
                            <rect
                              x="3"
                              y="3"
                              rx="10"
                              ry="10"
                              width={`${this.state.isMobile ? "380" : "100%"}`}
                              height="180"
                            />
                            <rect
                              x="6"
                              y="190"
                              rx="0"
                              ry="0"
                              width={`${this.state.isMobile ? "320" : "60%"}`}
                              height="20"
                            />
                            <rect
                              x="4"
                              y="215"
                              rx="0"
                              ry="0"
                              width="320"
                              height="20"
                            />
                            <rect
                              x="4"
                              y="242"
                              rx="0"
                              ry="0"
                              width="380"
                              height="20"
                            />
                          </ContentLoader>
                        );
                      })}
                    </div>
                  ) : (
                    <ul
                      className={`${
                        this.state.isMobile
                          ? "ml-view package-ul"
                          : "package-ul1"
                      }`}
                    >
                      {this.state?.trip_day_packages_status === "No" && (
                        <div className="notavailable-msg">
                          <InfoIcon /> {this.state?.trid_day_message}
                        </div>
                      )}
                      {this.state.packageLoading === false ? (
                        <>
                          {" "}
                          {this.state.filterResult.length > 0 ? (
                            <> {this.state.display_package}</>
                          ) : (
                            "No Packages Found"
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {/* <li className="designer-card dcard-blue">
                        <a href="#" className="over-link">
                          <div className="dcard-text">
                            <div className="dcard-text-inner">
                              <h3>Want to craft your own package?</h3>
                              <p>
                                know your destination well? Craft your dream
                                vacation itinerary by itinerary.
                              </p>
                            </div>
                          </div>
                          <img className="dcard-bg" src={cardbg} alt="cardbg" />
                        </a>
                        <a
                          href={void 0}
                          onClick={this.createOwnPackage.bind(this)}
                          className="button  cy-pack"
                        >
                          Create now
                        </a>
                      </li> */}
                    </ul>
                  )}
                </div>
              </div>
              <div className="offer-main">
                <div className="offer-design-card">
                  <div className="text1">
                    Enjoy <span>10%</span> Off when you signup and
                    <br /> other exclusive offers
                  </div>
                  <div className="text2">
                    Use code <span>FIRSTBOOKING10</span>
                  </div>
                  <div className="text3">T&C apply*</div>
                  <a
                    href={void 0}
                    onClick={this.openPopup.bind(
                      this,
                      "#customer-signup-popup"
                    )}
                    className="button cy-pack"
                  >
                    Signup now
                  </a>
                </div>
              </div>
              <div id="customer-signup-popup" className="mfp-hide common-popup">
                <SignupComponent />
              </div>
              {/* {this.state.packageLoading === true ? (
                <div className="search-loader">
                  {Array(1, 2, 3).map((item, index) => {
                    return (
                      <ContentLoader
                        viewBox="0 0 380 280"
                        height={280}
                        width={380}
                        speed={3}
                        key={index}
                      >
                        <rect
                          x="3"
                          y="3"
                          rx="10"
                          ry="10"
                          width="380"
                          height="180"
                        />
                        <rect
                          x="6"
                          y="190"
                          rx="0"
                          ry="0"
                          width="380"
                          height="20"
                        />
                        <rect
                          x="4"
                          y="215"
                          rx="0"
                          ry="0"
                          width="320"
                          height="20"
                        />
                        <rect
                          x="4"
                          y="242"
                          rx="0"
                          ry="0"
                          width="380"
                          height="20"
                        />
                      </ContentLoader>
                    );
                  })}
                </div>
              ) : (
                <ul className="package-ul">
                  {this.state.display_package}
                  <li className="designer-card dcard-blue">
                    <a href="#" className="over-link">
                      <div className="dcard-text">
                        <div className="dcard-text-inner">
                          <h3>Want to craft your own package?</h3>
                          <p>
                            know your destination well? Craft your dream
                            vacation itinerary by itinerary.
                          </p>
                        </div>
                      </div>
                      <img className="dcard-bg" src={cardbg} alt="cardbg" />
                    </a>
                    <a
                      href={void 0}
                      onClick={this.createOwnPackage.bind(this)}
                      className="button  cy-pack"
                    >
                      Create your own package
                    </a>
                  </li>
                </ul>
              )} */}
              {this.state.loadmore === true && (
                <div className="btm-load textcenter">
                  <a href="#" className="button ghost-button">
                    Load more
                  </a>
                </div>
              )}
            </div>
          </div>
        </section>
        <div
          id="filter-stick-popup"
          className="mfp-hide common-popup filter-stick-popup"
        >
          <div className="cp-header textcenter">
            <h3>Filters</h3>
            <a
              className="date-package-popup"
              href={void 0}
              // onClick={this.closeDate.bind(this)}
            ></a>
          </div>
          <div className="filter-body">
            <div className="filter-row pricerange-option">
              <h5>Price Range</h5>
              <p>
                {showPriceValue(Math.ceil(this.state.startPrice))} to{" "}
                {showPriceValue(Math.ceil(this.state.endPrice))}+
                <input
                  type="text"
                  id="rang"
                  name="rang"
                  defaultValue=""
                  data-min={Math.round(this.state.startPrice)}
                  data-max={Math.round(this.state.endPrice)}
                  data-from={this.state.startPrice}
                  data-to={this.state.endPrice}
                  data-type="double"
                  data-prefix="SGD$"
                  data-grid="false"
                  data-grid-num="10"
                />
              </p>
              <div></div>
            </div>
            <div className="filter-row">
              <h5>categories</h5>
              {this.state.category.map((item, index) => {
                return (
                  <ul className="filter-row-ul" key={index}>
                    <li key={index}>
                      <input
                        type="checkbox"
                        className="tag_filter"
                        id={"category" + index}
                        value={item}
                        onChange={this.handleChange.bind(this, "category")}
                      />
                      <label htmlFor={"category_" + index}>{item}</label>
                    </li>
                  </ul>
                );
              })}
            </div>

            {/*   <div className="filter-row">
              <h5>Options</h5>
              <ul className="filter-row-ul">
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
                <li>
                  <input type="checkbox" class="tag_filter" value="Concierge" />
                  <label>Concierge</label>
                </li>
              </ul>
            </div>
            */}
          </div>
          <div className="filter-action">
            <a
              href={void 0}
              className="button"
              onClick={this.handleApplyFilter.bind(this)}
            >
              {" "}
              Apply
            </a>
            <a
              href={void 0}
              className="button ghost-button"
              onClick={this.handleClearAll.bind(this)}
            >
              {" "}
              Clear All
            </a>
          </div>
        </div>

        {/* {this.state.isMobile && (
                    <div className="create_package">
                        <Fab
                            color="primary"
                            aria-label="add"
                            variant="extended"
                            onClick={this.createOwnPackage.bind(this)}
                        >
                            <AddIcon />
                            Create
                        </Fab>
                    </div>
                )} */}
        <BottomSheet
          open={this.state.openFilterSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openFilterSheet: false })}
        >
          <FilterSheet
            state={this.state}
            currentThis={this}
            categorylist={this.state.categorylist}
            handleChange={(type, event) => this.handleChange(type, event)}
            handleApplyFilter={this.handleApplyFilter.bind(this)}
            handleClearAll={this.handleClearAll.bind(this)}
            daysFilter={daysFilter}
            search_days={this.state.search_days}
            handleDaysFilter={this.handleDaysFilter.bind(this)}
            nextYearMonths={nextYearMonths}
            closeFilterSheet={() => this.setState({ openFilterSheet: false })}
          />
        </BottomSheet>
        <BottomSheet
          open={this.state.openSortSheet}
          className="bottomSheetMain"
          onDismiss={() => this.setState({ openSortSheet: false })}
        >
          <div className="searchContainer__bottomSheetMain__container">
            <div className="searchContainer__bottomSheetMain__container__sourceAir">
              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === ""
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "" },
                  });
                  this.setState({ openSortSheet: false });
                }}
              >
                None
              </div>
              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === "lowToHigh"
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "lowToHigh" },
                  });
                  this.setState({ openSortSheet: false });
                }}
              >
                Price: Low to High
              </div>
              <div
                className={`searchContainer__bottomSheetMain__container__sourceAir__row ${
                  this.state.sortOption === "highToLow"
                    ? "searchContainer__bottomSheetMain__container__sourceAir__row__active"
                    : ""
                }`}
                onClick={() => {
                  this.handleChange("sortOption", {
                    target: { value: "highToLow" },
                  });
                  this.setState({ openSortSheet: false });
                }}
              >
                Price: High to Low
              </div>
            </div>
          </div>
        </BottomSheet>
        <Footer {...this.props} />
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
  };
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(PromotionalPackage);
