/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
/* import cookie from "react-cookies"; */
import {} from "recharts";
import {
  apiUrl,
  UopUrl,
  fizoheader,
  fizoheader_formData,
  fizoheader_urlencoded,
  userapiUrl,
} from "../../Helpers/Config";
import "./ItineraryActivity.scss";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import addplusimg from "../../../common/images/add-imgplus.svg";
import backarrow from "../../../common/images/back-arrow-front.svg";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import axios from "axios";
import cookie from "react-cookies";
import DeleteIcon from "@mui/icons-material/Delete";

import { GET_CUSTOMER_DETAIL, GET_UPLOAD_FILES } from "../../../actions";
import { Button, IconButton } from "@mui/material";
import CustomEditor from "../CustomEditor/CustomEditor";
import CarouselModal from "../CarouselModal/CarouselModal";
import { LocalizationProvider } from "@mui/x-date-pickers";
import useUnsavedChangesWarning from "../../Help/UnsavedPopup";
var qs = require("qs");

class Listpromo extends Component {
  constructor(props) {
    super(props);
    console.log("detailsPage", this.props);
    var CUserId = "";
    if (
      cookie.load("CUserId") !== "" &&
      typeof cookie.load("CUserId") !== undefined &&
      typeof cookie.load("CUserId") !== "undefined"
    ) {
      CUserId = cookie.load("CUserId");
    } else {
      this.props.history.push("/");
    }

    if (this.props?.location?.state?.day !== undefined) {
      var day = this.props?.location?.state?.day;
    } else {
      var day = 1;
    }

    var destList = [];
    var postedDay = [];
    let postedSection = [];
    if (this.props?.location?.state?.destList !== undefined) {
      destList = this.props?.location?.state?.destList;
    }
    if (this.props?.location?.state?.postedDay !== undefined) {
      postedDay = this.props?.location?.state?.postedDay;
    }
    let packageID = "";
    if (this.props?.location?.state?.packageID !== undefined) {
      packageID = this.props?.location?.state?.packageID;
    }
    if (this.props?.location?.state?.creator_package_id !== undefined) {
      packageID = this.props?.location?.state?.creator_package_id;
    }
    let allowedSection = [];
    if (this.props?.location?.state?.allowedSection !== undefined) {
      allowedSection = this.props.location.state.allowedSection;
    }
    let duration = [];
    if (this.props?.location?.state?.duration !== undefined) {
      duration = this.props.location.state.duration;
    }
    if (!allowedSection?.includes("itinerary-list")) {
      this.props.history.push("/myaccount/general-info");
    }
    let isNewPackageDay = true;
    if (this.props?.location?.state?.isNewPackageDay !== undefined) {
      isNewPackageDay = this.props.location.state.isNewPackageDay;
    }
    if (this.props?.location?.state?.postedSection !== undefined) {
      postedSection = this.props?.location?.state?.postedSection;
    }
    let productType = "";
    if (this.props?.location?.state?.productType !== undefined) {
      productType = this.props?.location?.state?.productType;
    }
    this.state = {
      CUserId: CUserId,
      customerdetail: "",
      isLoading: true,
      packageLoading: true,
      creator: [],
      creator_package_id: packageID,
      day: day,
      day_title: "",
      day_description: "",
      previousStay: false,
      newStay: true,
      transfer_included: false,
      guide_included: false,
      purpose_activities: false,
      pickUp: "",
      dropOff: "",
      location: "",
      purpose_staysMedia: false,
      activities: [{ item: "", images: "" }],
      foodAndBev: [{ item: "", type: "" }],
      transporStops: [""],
      stays: [
        {
          stay_name: "",
          stay_address: "",
          check_in: "",
          check_out: "",
          room_type: [""],
          food_covered: [],
          media: "",
        },
      ],
      destList: (destList ?? [])
        .filter((city) => city?.trim() !== "")
        .map((city) => city),
      postedDay: postedDay,
      selectedTab: 1,
      packageID: packageID,
      errors: {},
      openModal: false,
      carouselData: [],
      packageDayID: 1,
      duration: duration,
      isNewPackage: isNewPackageDay,
      postedSection: postedSection,
      isFormEdited: false,
      productType: productType,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
    let posted = this.state.postedSection.includes("itinerary-list");
    if (!this.state.isNewPackage || posted) {
      this.getItineraryData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.customerdetail !== nextProps.customerdetail) {
      var customerDetails = nextProps.customerdetail;
      this.setState({
        customerdetail: customerDetails,
      });
    }
    if (
      this.state.purpose_activities &&
      nextProps.uploadfiles.web !== undefined
    ) {
      const { activities } = this.state;
      let updatedSets = [...activities];

      updatedSets.forEach((activity) => {
        let mediaArray = activity.images.split(",");
        let placeholderIndex = mediaArray.indexOf("");
        if (placeholderIndex !== -1) {
          mediaArray[placeholderIndex] = nextProps.uploadfiles.web;
          activity.images = mediaArray.join(",");
        }
      });

      this.setState({
        activities: updatedSets,
        purpose_activities: false,
      });
    }
    if (
      this.state.purpose_staysMedia &&
      nextProps.uploadfiles.web !== undefined
    ) {
      const { stays } = this.state;
      let updatedStays = [...stays];

      updatedStays.forEach((stay) => {
        let mediaArray = stay.media.split(",");
        let placeholderIndex = mediaArray.indexOf("");
        if (placeholderIndex !== -1) {
          mediaArray[placeholderIndex] = nextProps.uploadfiles.web;
          stay.media = mediaArray.join(",");
        }
      });

      this.setState({
        stays: updatedStays,
        purpose_staysMedia: false,
      });
    }
  }

  getItineraryData = async () => {
    try {
      const { day, packageID, transporStops, stays } = this.state;
      await axios
        .get(`${UopUrl}/itinerary?id=${packageID}`, fizoheader)
        .then((res) => {
          if (res.data !== undefined || res.data.length > 0) {
            const previousStay = res.data[day - 1].previous_stay === "true";
            const guide_included = res.data[day - 1].guide_included === "true";
            const transfer_included =
              res.data[day - 1].transfer_included === "true";
            this.setState({
              packageDayID: res.data[day - 1].id,
              day_title: res.data[day - 1].title,
              day_description: res.data[day - 1].description,
              stays: res.data[day - 1].stay || stays,
              transfer_included: transfer_included,
              guide_included: guide_included,
              pickUp: res.data[day - 1].pick_up,
              dropOff: res.data[day - 1].drop_off,
              location: res.data[day - 1].location,
              transporStops: res.data[day - 1].transport_stops ?? transporStops,
              activities: res.data[day - 1].things_to_do,
              foodAndBev: res.data[day - 1].food_beverage,
              previousStay: previousStay,
              newStay: !previousStay,
              // destList: res.data[day],
            });
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  };

  handleChange = (name, event) => {
    try {
      const { checked, value } = event.target;
      var errors = {};

      if (name === "previousStay" || name === "newStay") {
        if (checked) {
          let field = name === "previousStay" ? "newStay" : "previousStay";
          this.setState({ [name]: true, [field]: false, isFormEdited: true });
        } else {
          this.setState({ [name]: false, isFormEdited: true });
        }
      } else if (name === "transfer_included" || name === "guide_included") {
        this.setState({ [name]: checked, isFormEdited: true });
      } else if (name === "checkIn" || name === "checkOut") {
        errors[name] = "";
        this.setState({
          [name]: value.replace(/[^0-9:aamp]/g, ""),
          errors,
          isFormEdited: true,
        });
      } else {
        errors[name] = "";
        this.setState({
          [name]: value.replace(/[^\s\S]/g, ""),
          errors,
          isFormEdited: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleMediaRemove = (index, mediaIndex, field) => {
    try {
      this.setState((prevState) => {
        const updatedField = [...prevState[field]];
        if (field === "stays") {
          // Split the media string into an array
          const mediaArray = updatedField[index].media.split(",");
          // Remove the element at the specified index from the array
          mediaArray.splice(mediaIndex, 1);
          // Join the array back into a string
          updatedField[index].media = mediaArray.join(",");
        } else {
          const mediaArray = updatedField[index].images.split(",");
          // Remove the element at the specified index from the array
          mediaArray.splice(mediaIndex, 1);
          // Join the array back into a string
          updatedField[index].images = mediaArray.join(",");
        }
        return { [field]: updatedField, isFormEdited: true };
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleEditorChange = (event, editor) => {
    var data = editor.getData();
    // const wordLimit = 150;
    // const charLimit = 300;
    // const sanitizedValue = data.replace(/[^a-zA-Z0-9\s<>/]/g, "");
    // const words = sanitizedValue.split(/\s+/).length;
    // if (words <= wordLimit && sanitizedValue.length <= charLimit) {
    //   const truncatedValue = sanitizedValue
    //     .split(/\s+/)
    //     .slice(0, wordLimit)
    //     .join(" ");
    this.setState({ day_description: data, isFormEdited: true }, () =>
      console.log(this.state.day_description)
    );
    // }
  };

  convertToTimeObject = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
  };

  uploadDayDetails = async () => {
    console.log(this.state.stays, "stays");
    try {
      const errors = this.validateForm();
      console.log(errors, "erros");
      const { packageID, packageDayID, isNewPackage } = this.state;
      if (Object.keys(errors).length > 0) {
        this.setState({ errors }, () => {
          if (
            Object.keys(errors).length === 1 &&
            errors.description !== undefined
          ) {
            this.handleSelectedTab(2);
          }
        });
      } else {
        this.setState({ isLoading: true, isFormEdited: false });
        let formData = new FormData();
        formData.append("creator_id", this.state.CUserId);
        formData.append("creator_package_id", this.state.creator_package_id);
        formData.append("day", this.state.day);
        formData.append("title", this.state.day_title);
        formData.append("description", this.state.day_description);
        formData.append("stay", JSON.stringify(this.state.stays));
        formData.append("transfer_included", this.state.transfer_included);
        formData.append("guide_included", this.state.guide_included);
        formData.append("pick_up", this.state.pickUp);
        formData.append("drop_off", this.state.dropOff);
        formData.append("location", this.state.location);
        formData.append("previous_stay", this.state.previousStay);
        formData.append(
          "transport_stops",
          JSON.stringify(this.state.transporStops)
        );
        formData.append("things_to_do", JSON.stringify(this.state.activities));
        formData.append("food_beverage", JSON.stringify(this.state.foodAndBev));

        let res;
        if (isNewPackage) {
          res = await axios.post(
            `${UopUrl}/itinerary`,
            formData,
            fizoheader_formData
          );
        } else {
          var urlencoded = new URLSearchParams();
          urlencoded.append("creator_id", this.state.CUserId);
          urlencoded.append(
            "creator_package_id",
            this.state.creator_package_id
          );
          urlencoded.append("day", this.state.day);
          urlencoded.append("title", this.state.day_title);
          urlencoded.append("description", this.state.day_description);
          urlencoded.append("stay", JSON.stringify(this.state.stays));
          urlencoded.append("check_in", this.state.checkIn);
          urlencoded.append("check_out", this.state.checkOut);
          urlencoded.append("transfer_included", this.state.transfer_included);
          urlencoded.append("guide_included", this.state.guide_included);
          urlencoded.append("pick_up", this.state.pickUp);
          urlencoded.append("drop_off", this.state.dropOff);
          urlencoded.append("location", this.state.location);
          urlencoded.append("previous_stay", this.state.previousStay);
          urlencoded.append(
            "transport_stops",
            JSON.stringify(this.state.transporStops)
          );
          urlencoded.append(
            "things_to_do",
            JSON.stringify(this.state.activities)
          );
          urlencoded.append(
            "food_beverage",
            JSON.stringify(this.state.foodAndBev)
          );
          res = await axios.put(
            `${UopUrl}/itinerary/update/${packageDayID}`,
            urlencoded,
            fizoheader_urlencoded
          );
        }

        console.log(res);
        if (res.status === 200) {
          this.setState({ isLoading: false });
          var postedDay = [...this.state.postedDay];
          let postedSection = [...this.state.postedSection];
          if (!postedDay.includes(this.state.day)) {
            postedDay.push(this.state.day);
          }
          if (!postedSection.includes("itinerary-list")) {
            postedSection.push("itinerary-list");
          }
          var propsDetails = this.props?.location?.state;
          propsDetails["postedDay"] = postedDay;
          propsDetails["postedSection"] = postedSection;
          console.log(propsDetails, "propsDetails");
          this.props.history.push({
            pathname: "/myaccount/itinerary-list",
            state: propsDetails,
          });
        }
      }
    } catch (err) {
      console.log(err);
      this.setState({ isLoading: false });
    }
  };

  validateForm = () => {
    console.log(this.state.stays, "stays");
    const {
      day_title,
      stays,
      dropOff,
      pickUp,
      day_description,
      location,
      newStay,
      previousStay,
      day,
      transfer_included,
      destList,
    } = this.state;
    const errors = {};

    if (day_title.trim() === "") {
      errors.title = "day title required";
    }
    if (day_description.trim() === "") {
      errors.description = "day description required";
    }
    if (destList.length > 0 && destList.summary !== "") {
      if (location.trim() === "") {
        errors.location = "location required";
      }
    }
    if (transfer_included) {
      if (dropOff === "") {
        errors.dropOff = "dropOff required";
      }
      if (pickUp === "") {
        errors.pickUp = "pickUp required";
      }
    }
    /*     if (!transfer_included) {
      errors.transfer_included = "transfer required";
    } */
    /*   if (day === 1 && !newStay) {
      errors.newStay = "stay selection required";
    } */
    if (day > 1 && !newStay && !previousStay) {
      errors.newStay = "stay selection required";
    }
    if (day > 1 && newStay) {
      stays.forEach((stay, index) => {
        if (stay.stay_name === "") {
          errors[`stay_name_${index}`] = `stay name required`;
        }
        if (stay.check_in === "") {
          errors[`check_in_${index}`] = `check in required`;
        }
        // else if (
        //   !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9] ?(?:am|pm)$/i.test(stay.check_in)
        // ) {
        //   errors[`check_in_${index}`] = `invalid check in time format`;
        // }
        if (stay.check_out === "") {
          errors[`check_out_${index}`] = `check out required`;
        }
        // else if (
        //   !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9] ?(?:am|pm)$/i.test(stay.check_out)
        // ) {
        //   errors[`check_out_${index}`] = `invalid check out time format`;
        // }
      });
    } else if (day === 1) {
      stays.forEach((stay, index) => {
        if (stay.stay_name === "") {
          errors[`stay_name_${index}`] = `stay name required`;
        }
        if (stay.check_in === "") {
          errors[`check_in_${index}`] = `check in required`;
        }
        // else if (
        //   !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9] ?(?:am|pm)$/i.test(stay.check_in)
        // ) {
        //   errors[`check_in_${index}`] = `invalid check in time format`;
        // }
        if (stay.check_out === "") {
          errors[`check_out_${index}`] = `check out required`;
        }
        // else if (
        //   !/^([0-1]?[0-9]|2[0-3]):[0-5][0-9] ?(?:am|pm)$/i.test(stay.check_out)
        // ) {
        //   errors[`check_out_${index}`] = `invalid check out time format`;
        // }
      });
    }

    return errors;
  };

  addHighlights = (event, index, name) => {
    try {
      let fieldSet = this.state[name];

      let updatedSets = [...fieldSet];

      if (name === "transportStops") {
        updatedSets[index] = event.target.checked;
      } else {
        updatedSets[index] = event.target.value.replace(/[^\s\S]/g, "");
      }

      this.setState(
        {
          [name]: updatedSets,
          isFormEdited: true,
        },
        () => console.log(this.state[name])
      );
    } catch (err) {
      console.log(err);
    }
  };

  handleRoomTypes = (index, roomIndex, event) => {
    const { value } = event.target;
    const { stays } = this.state;
    let updatedStays = [...stays];
    updatedStays[index].room_type[roomIndex] = value.replace(/[^\s\S]/g, "");
    this.setState({ stays: updatedStays, isFormEdited: true });
  };

  handleFoodBev = (index, field, event) => {
    const { value } = event.target;
    const { foodAndBev } = this.state;
    let updatedSets = [...foodAndBev];
    if (field === "item") {
      updatedSets[index].item = value.replace(/[^\s\S]/g, "");
    } else {
      updatedSets[index].type = value;
    }
    this.setState({
      foodAndBev: updatedSets,
      isFormEdited: true,
    });
  };

  handleActivities = async (index, field, event) => {
    try {
      const { value, files } = event.target;
      const { activities } = this.state;
      let updatedSets = [...activities];
      if (field === "item") {
        updatedSets[index].item = value.replace(/[^\s\S]/g, "");
      } else {
        const selectedFile = files[0];
        if (!selectedFile) return;

        var formData = new FormData();
        formData.append("file", selectedFile);
        await this.props.GetUploadFiles(formData);

        let mediaArray;
        if (updatedSets[index].images !== "") {
          mediaArray = updatedSets[index].images.split(",");
        } else {
          mediaArray = [];
        }
        mediaArray.push("");
        updatedSets[index].images = mediaArray.join(",");
      }
      this.setState({
        activities: updatedSets,
        isFormEdited: true,
        purpose_activities: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleStayDetails = async (index, field, event, roomIndex) => {
    try {
      const { value, checked, files } = event.target;
      const { stays } = this.state;
      if (field === "room_type") {
        this.setState((prevState) => {
          const updatedStays = [...prevState.stays];
          updatedStays[index].room_type[roomIndex] = value;
          return { stays: updatedStays, isFormEdited: true };
        });
      } else if (field === "media") {
        const selectedFile = files[0];
        if (!selectedFile) return;

        var formData = new FormData();
        formData.append("file", selectedFile);
        await this.props.GetUploadFiles(formData);

        this.setState((prevState) => {
          const updatedStays = [...prevState.stays];
          let mediaArray;
          if (updatedStays[index].media && updatedStays[index].media !== "") {
            mediaArray = updatedStays[index].media.split(",");
          } else {
            mediaArray = [];
          }
          mediaArray.push(""); // Placeholder to be replaced in componentWillReceiveProps
          updatedStays[index].media = mediaArray.join(",");
          return {
            stays: updatedStays,
            isFormEdited: true,
            purpose_staysMedia: true,
          };
        });
      } else if (
        field == "breakfast" ||
        field == "lunch" ||
        field == "dinner"
      ) {
        this.setState((prevState) => {
          const updatedStays = [...prevState.stays];
          if (checked) {
            updatedStays[index].food_covered.push(field);
          } else {
            updatedStays[index].food_covered = updatedStays[
              index
            ].food_covered.filter((item) => item !== field);
          }
          return { stays: updatedStays, isFormEdited: true };
        });
      } else {
        this.setState((prevState) => {
          const updatedStays = [...prevState.stays];
          updatedStays[index][field] = value;
          return { stays: updatedStays, isFormEdited: true };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleTimeSelection = (index, field, value) => {
    let timeValue = dayjs(value.$d).format("HH:mm");
    // console.log(index, value, typeof value);
    this.setState(
      (prevState) => {
        const updatedStays = [...prevState.stays];
        updatedStays[index][field] = timeValue;
        return { stays: updatedStays, isFormEdited: true };
      }
      // () => console.log(this.state.stays)
    );
  };

  handleRemove = (index, name, stayIndex) => {
    try {
      let list;
      let minimumValue = 1;
      if (index < minimumValue) {
        this.setState({ disableBtn: true });
      } else {
        if (name === "room_type") {
          list = [...this.state.stays];
          list[stayIndex].room_type.splice(index, 1);
          this.setState({ stays: list, isFormEdited: true });
        } else {
          list = [...this.state[name]];
          list.splice(index, 1);
          this.setState({ [name]: list, isFormEdited: true });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  handleOpen = (data) => {
    this.setState({ openModal: true, carouselData: data });
  };

  handleAdd = (name, index) => {
    try {
      if (name === "foodAndBev") {
        this.setState((prevState) => ({
          [name]: [...prevState[name], { item: "", type: "" }],
          isFormEdited: true,
        }));
      } else if (name === "stays") {
        this.setState((prevState) => ({
          stays: [
            ...prevState.stays.slice(0, index), // Copy stays before the index
            {
              // New stay object
              stay_name: "",
              stay_address: "",
              check_in: "",
              check_out: "",
              room_type: [""],
              food_covered: [],
              media: "",
            },
            ...prevState.stays.slice(index), // Copy stays after the index
          ],
          isFormEdited: true,
        }));
      } else if (name === "activities") {
        this.setState((prevState) => ({
          [name]: [...prevState[name], { item: "", images: "" }],
          isFormEdited: true,
        }));
      } else {
        this.setState((prevState) => ({
          [name]: [...prevState[name], ""],
          isFormEdited: true,
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleStayRoom = (stayIndex) => {
    this.setState((prevState) => ({
      isFormEdited: true,
      stays: prevState.stays.map((stay, sIndex) => {
        if (sIndex === stayIndex) {
          return {
            ...stay,
            room_type: [...stay.room_type, ""], // Add a new element to the end of the room_type array
          };
        }
        return stay; // Return the stay unchanged
      }),
    }));
  };

  getImageUrl = async (file) => {
    try {
      let formData = new FormData();
      formData.append("image", file);

      const response = await axios.post(
        userapiUrl + "settings/uploadFiles",
        formData,
        fizoheader
      );

      if (response.data.status === "ok") {
        return response.data.url;
      } else {
        throw new Error("Failed to get image URL");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  handleSelectedTab = (num) => {
    this.setState({ selectedTab: num });
  };

  handleDeleteStay = (index) => {
    let stay = [...this.state.stays];
    stay.splice(index, 1);
    this.setState({ stays: stay, isFormEdited: true });
  };
  goBack = () => {
    if (!this.state.isFormEdited) {
      this.props.history.goBack();
    }
  };

  render() {
    const { errors, stays, activities, day, destList } = this.state;

    var foodList = ["BreakFast", "Lunch", "Dinner", "Snacks", "Drinks"];
    return (
      <>
        {this.state.isLoading === false ? (
          <>
            {this.state.creator !== "" && (
              <>
                <section className="main-blue-bg itineraryactivity-main">
                  <div className="container-full">
                    <div className="standfirst">
                      <h2 className="color-header">
                        <a
                          href={void 0}
                          onClick={() => this.goBack()}
                          className="arrow-big"
                        >
                          {" "}
                          <img src={backarrow} />{" "}
                        </a>
                        Day {this.state.day}
                      </h2>
                      <button
                        href={void 0}
                        onClick={this.uploadDayDetails}
                        className="button btn-scoi"
                        // disabled={!allFieldsFilled}
                      >
                        Save & continue
                      </button>
                    </div>

                    <div className="itineraryactivity-main__content">
                      <div className="itineraryactivity-main__content__tabs">
                        <div
                          className={`itineraryactivity-main__content__tabs__tab ${
                            this.state.selectedTab === 1 ? "active" : ""
                          }`}
                          onClick={() => this.handleSelectedTab(1)}
                        >
                          Info
                        </div>
                        <div
                          className={`itineraryactivity-main__content__tabs__tab ${
                            this.state.selectedTab === 2 ? "active" : ""
                          }`}
                          onClick={() => this.handleSelectedTab(2)}
                        >
                          Day description
                        </div>
                      </div>
                      {this.state.selectedTab === 1 ? (
                        <>
                          <div className="billboard billboard-with-nav">
                            <div className="billboard-nav"></div>
                            <div className="billboard-lhs">
                              <div className="form-group">
                                <label className="control-label">
                                  Day title <em>*</em>
                                </label>
                                <input
                                  placeholder="Ex: Trekking with night camp"
                                  type="text"
                                  value={this.state.day_title}
                                  onChange={(e) =>
                                    this.handleChange("day_title", e)
                                  }
                                />
                                {errors.title && (
                                  <p style={{ color: "red" }}>{errors.title}</p>
                                )}
                              </div>
                              {day > 1 && (
                                <div className="form-group big-textarea">
                                  <label className="control-label">
                                    Stay for the day <em>*</em>
                                  </label>
                                </div>
                              )}

                              <div className="itinerary-list">
                                {day > 1 && (
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      checked={this.state.previousStay}
                                      onChange={(e) =>
                                        this.handleChange("previousStay", e)
                                      }
                                      // disabled={this.state.previousStay}
                                    />
                                    &nbsp;&nbsp;
                                    <label className="control-label">
                                      Same as previous day
                                    </label>
                                  </div>
                                )}
                                {day > 1 && (
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      checked={this.state.newStay}
                                      onChange={(e) =>
                                        this.handleChange("newStay", e)
                                      }
                                      // disabled={this.state.newStay}
                                    />
                                    &nbsp;&nbsp;
                                    <label className="control-label">
                                      Add new stay for the day
                                    </label>
                                  </div>
                                )}
                              </div>
                              {errors.newStay && (
                                <p style={{ color: "red" }}>{errors.newStay}</p>
                              )}
                            </div>
                            <div className="billboard-rhs">
                              <div className="billboard-top">
                                {destList.length > 0 && destList !== "" && (
                                  <div className="form-group">
                                    <label className="control-label">
                                      City/area <em>*</em>
                                    </label>
                                    <select
                                      name="city/area"
                                      value={this.state.location}
                                      onChange={(e) =>
                                        this.handleChange("location", e)
                                      }
                                    >
                                      <option value="">Select</option>
                                      {destList?.map((city, index) => {
                                        if (city.trim() !== "") {
                                          return (
                                            <option value={city}>{city}</option>
                                          );
                                        }
                                      })}
                                    </select>
                                    {errors.location && (
                                      <p style={{ color: "red" }}>
                                        {errors.location}
                                      </p>
                                    )}
                                  </div>
                                )}
                                <div className="form-group big-textarea">
                                  <label className="control-label">
                                    Transportation & Guide{/*  <em>*</em> */}
                                  </label>
                                </div>
                                <div className="itinerary-list">
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      checked={this.state.transfer_included}
                                      onChange={(e) =>
                                        this.handleChange(
                                          "transfer_included",
                                          e
                                        )
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    <label className="control-label">
                                      Transfer included
                                    </label>
                                  </div>
                                  <div className="form-group">
                                    <input
                                      type="checkbox"
                                      checked={this.state.guide_included}
                                      onChange={(e) =>
                                        this.handleChange("guide_included", e)
                                      }
                                    />
                                    &nbsp;&nbsp;
                                    <label className="control-label">
                                      Guide included
                                    </label>
                                  </div>
                                </div>
                                {/*  {errors.transfer_included && (
                                  <p style={{ color: "red" }}>
                                    {errors.transfer_included}
                                  </p>
                                )} */}
                              </div>
                            </div>
                          </div>
                          <div className="itineraryactivity-main__content__thingstodo">
                            <div className="itineraryactivity-main__content__thingstodo__heading">
                              Things to do information
                            </div>
                            <div className="form-group soc-add">
                              {this.state.activities.map((item, index) => (
                                <>
                                  <div className="pHightlights">
                                    <input
                                      placeholder="Ex: universal studio."
                                      type="text"
                                      value={item.item}
                                      onChange={(e) =>
                                        this.handleActivities(index, "item", e)
                                      }
                                    />
                                    <div className="itineraryactivity-main__content__thingstodo__actions">
                                      {item.images?.split(",").length < 5 && (
                                        <div className="itineraryactivity-main__content__thingstodo__img-box">
                                          <input
                                            type="file"
                                            className="file-input"
                                            onChange={(e) =>
                                              this.handleActivities(
                                                index,
                                                "images",
                                                e
                                              )
                                            }
                                          />
                                          <a>
                                            <figure>
                                              <img src={addplusimg} />
                                              <figcaption>Add image</figcaption>
                                            </figure>
                                          </a>
                                        </div>
                                      )}
                                      <a
                                        className="button soc-btn"
                                        onClick={() =>
                                          this.handleRemove(index, "activities")
                                        }
                                      >
                                        -
                                      </a>
                                      <a
                                        className="button soc-btn"
                                        onClick={() =>
                                          this.handleAdd("activities")
                                        }
                                      >
                                        +
                                      </a>
                                    </div>
                                  </div>
                                  <br />
                                  <div className="fgp-add-row">
                                    {item?.images !== undefined && (
                                      <ul>
                                        {Array.from(item.images.split(","))
                                          .length > 0 &&
                                          Array.from(
                                            item.images.split(",")
                                          )[0] !== "" &&
                                          Array.from(
                                            item.images.split(",")
                                          ).map((mediaItem, mediaIndex) => (
                                            <li>
                                              <div className="selected-img">
                                                <img
                                                  src={
                                                    typeof mediaItem ===
                                                      "string" &&
                                                    mediaItem?.includes(
                                                      "https"
                                                    ) &&
                                                    mediaItem
                                                  }
                                                  onClick={() =>
                                                    this.handleOpen(
                                                      item.images,
                                                      "img"
                                                    )
                                                  }
                                                />
                                                <IconButton
                                                  onClick={() =>
                                                    this.handleMediaRemove(
                                                      index,
                                                      mediaIndex,
                                                      "activities"
                                                    )
                                                  }
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </div>
                                            </li>
                                          ))}
                                      </ul>
                                    )}
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                          {this.state.transfer_included && (
                            <div className="itineraryactivity-main__content__transport">
                              <div className="itineraryactivity-main__content__transport__heading">
                                Transportation{" "}
                              </div>
                              <div className="billboard billboard-with-nav">
                                <div className="billboard-nav"></div>
                                <div className="billboard-lhs">
                                  <div className="form-group">
                                    <label className="control-label">
                                      Pick up <em>*</em>
                                    </label>
                                    <input
                                      type="text"
                                      placeholder="Ex: Near vandalur zoo"
                                      value={this.state.pickUp}
                                      onChange={(e) =>
                                        this.handleChange("pickUp", e)
                                      }
                                    />
                                    {errors.pickUp && (
                                      <p style={{ color: "red" }}>
                                        {errors.pickUp}
                                      </p>
                                    )}
                                  </div>
                                  <div className="form-group">
                                    <label className="control-label">
                                      Stop {/* <em>*</em> */}
                                    </label>
                                    <div className="form-group soc-add">
                                      {this.state.transporStops.map(
                                        (item, index) => (
                                          <>
                                            <div className="pHightlights">
                                              <select
                                                name="food"
                                                value={item}
                                                onChange={(e) =>
                                                  this.addHighlights(
                                                    e,
                                                    index,
                                                    "transporStops"
                                                  )
                                                }
                                              >
                                                <option value="">Select</option>
                                                {activities
                                                  ?.filter(
                                                    (stop) => stop.item !== ""
                                                  )
                                                  .map((stop, index) => {
                                                    var isDisabled =
                                                      this.state.transporStops.includes(
                                                        stop.item
                                                      );
                                                    return (
                                                      <option
                                                        value={stop.item}
                                                        disabled={isDisabled}
                                                      >
                                                        {stop.item}
                                                      </option>
                                                    );
                                                  })}
                                              </select>
                                              <div className="actions">
                                                <a
                                                  className="button soc-btn"
                                                  onClick={() =>
                                                    this.handleRemove(
                                                      index,
                                                      "transporStops"
                                                    )
                                                  }
                                                >
                                                  -
                                                </a>
                                                <a
                                                  className="button soc-btn"
                                                  onClick={() =>
                                                    this.handleAdd(
                                                      "transporStops"
                                                    )
                                                  }
                                                >
                                                  +
                                                </a>
                                              </div>
                                            </div>
                                            <br />
                                          </>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="billboard-rhs">
                                  <div className="billboard-top">
                                    <div className="form-group">
                                      <label className="control-label">
                                        Drop off <em>*</em>
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Ex: Near vandalur zoo"
                                        value={this.state.dropOff}
                                        onChange={(e) =>
                                          this.handleChange("dropOff", e)
                                        }
                                      />
                                      {errors.dropOff && (
                                        <p style={{ color: "red" }}>
                                          {errors.dropOff}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="itineraryactivity-main__content__food">
                            <div className="itineraryactivity-main__content__food__heading">
                              Food & Beverage{" "}
                            </div>
                            <div className="form-group soc-add">
                              {this.state.foodAndBev?.map((item, index) => (
                                <>
                                  <label className="control-label">
                                    Item 1 {/* <em>*</em> */}
                                  </label>
                                  <div className="pHightlights">
                                    <input
                                      placeholder="Ex: Biriyani"
                                      type="text"
                                      value={item.item}
                                      onChange={(e) =>
                                        this.handleFoodBev(index, "item", e)
                                      }
                                    />
                                    <select
                                      name="food"
                                      value={item.type}
                                      onChange={(e) =>
                                        this.handleFoodBev(index, "type", e)
                                      }
                                    >
                                      <option value="">Select</option>
                                      {foodList?.map((food, index) => {
                                        return (
                                          <option value={food}>{food}</option>
                                        );
                                      })}
                                    </select>
                                    <div className="actions">
                                      <a
                                        className="button soc-btn"
                                        onClick={() =>
                                          this.handleRemove(index, "foodAndBev")
                                        }
                                      >
                                        -
                                      </a>
                                      <a
                                        className="button soc-btn"
                                        onClick={() =>
                                          this.handleAdd("foodAndBev")
                                        }
                                      >
                                        +
                                      </a>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              ))}
                            </div>
                          </div>

                          {(day === 1 || (day > 1 && this.state.newStay)) &&
                            this.state.productType !== "dayTour" && (
                              <div className="itineraryactivity-main__content__stay">
                                <div className="itineraryactivity-main__content__stay__heading">
                                  Stay information
                                  <Button
                                    variant="text"
                                    startIcon={<AddIcon />}
                                    onClick={() =>
                                      this.handleAdd("stays", stays.length)
                                    }
                                  >
                                    Add more stay options
                                  </Button>
                                </div>
                                {stays?.length &&
                                  stays.map((item, index) => (
                                    <div className="itineraryactivity-main__content__stay__content">
                                      {index > 0 && (
                                        <IconButton
                                          className="itineraryactivity-main__content__stay__content__deleteStay"
                                          onClick={() =>
                                            this.handleDeleteStay(index)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                      <div className="billboard billboard-with-nav">
                                        <div className="billboard-lhs">
                                          <div className="form-group">
                                            <label className="control-label">
                                              Stay name <em>*</em>
                                            </label>

                                            <input
                                              placeholder="Ex: Grand Chozhla"
                                              type="text"
                                              value={item.stay_name}
                                              onChange={(e) =>
                                                this.handleStayDetails(
                                                  index,
                                                  "stay_name",
                                                  e
                                                )
                                              }
                                            />
                                            {errors[`stay_name_${index}`] && (
                                              <p style={{ color: "red" }}>
                                                {errors[`stay_name_${index}`]}
                                              </p>
                                            )}
                                          </div>
                                          <div className="checkin-row">
                                            <div className="form-group">
                                              <label className="control-label">
                                                Check in <em>*</em>
                                              </label>

                                              <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <MobileTimePicker
                                                  onChange={(newValue) =>
                                                    this.handleTimeSelection(
                                                      index,
                                                      "check_in",
                                                      newValue
                                                    )
                                                  }
                                                  value={
                                                    typeof item.check_in ===
                                                    "string"
                                                      ? dayjs(
                                                          item.check_in,
                                                          "HH:mm"
                                                        )
                                                      : item.check_in
                                                  }
                                                  ampm={false}
                                                  placeholder="Ex: 12:00am"
                                                />
                                              </LocalizationProvider>

                                              {errors[`check_in_${index}`] && (
                                                <p style={{ color: "red" }}>
                                                  {errors[`check_in_${index}`]}
                                                </p>
                                              )}
                                            </div>
                                            <div className="form-group">
                                              <label className="control-label">
                                                Check out <em>*</em>
                                              </label>

                                              <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <MobileTimePicker
                                                  onChange={(newValue) =>
                                                    this.handleTimeSelection(
                                                      index,
                                                      "check_out",
                                                      newValue
                                                    )
                                                  }
                                                  value={
                                                    typeof item.check_out ===
                                                    "string"
                                                      ? dayjs(
                                                          item.check_out,
                                                          "HH:mm"
                                                        )
                                                      : item.check_out
                                                  }
                                                  ampm={false}
                                                  placeholder="Ex: 12:00am"
                                                />
                                              </LocalizationProvider>

                                              {errors[`check_out_${index}`] && (
                                                <p style={{ color: "red" }}>
                                                  {errors[`check_out_${index}`]}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="form-group big-textarea">
                                            <label className="control-label">
                                              Food & Beverage {/* <em>*</em> */}
                                            </label>
                                          </div>
                                          <div className="itinerary-list">
                                            <div className="form-group">
                                              <input
                                                type="checkbox"
                                                checked={item.food_covered?.includes(
                                                  "breakfast"
                                                )}
                                                onChange={(e) =>
                                                  this.handleStayDetails(
                                                    index,
                                                    "breakfast",
                                                    e
                                                  )
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              <label className="control-label">
                                                Breakfast
                                              </label>
                                            </div>
                                            <div className="form-group">
                                              <input
                                                type="checkbox"
                                                checked={item.food_covered?.includes(
                                                  "lunch"
                                                )}
                                                onChange={(e) =>
                                                  this.handleStayDetails(
                                                    index,
                                                    "lunch",
                                                    e
                                                  )
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              <label className="control-label">
                                                Lunch
                                              </label>
                                            </div>
                                            <div className="form-group">
                                              <input
                                                type="checkbox"
                                                checked={item.food_covered?.includes(
                                                  "dinner"
                                                )}
                                                onChange={(e) =>
                                                  this.handleStayDetails(
                                                    index,
                                                    "dinner",
                                                    e
                                                  )
                                                }
                                              />
                                              &nbsp;&nbsp;
                                              <label className="control-label">
                                                Dinner
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="billboard-rhs">
                                          <div className="billboard-top">
                                            <div className="form-group">
                                              <label className="control-label">
                                                Stay address{/*  <em>*</em> */}
                                              </label>
                                              <input
                                                type="text"
                                                placeholder="Ex: No:12 east coast road"
                                                value={item.stay_address}
                                                onChange={(e) =>
                                                  this.handleStayDetails(
                                                    index,
                                                    "stay_address",
                                                    e
                                                  )
                                                }
                                              />
                                              {errors.stay_address && (
                                                <p style={{ color: "red" }}>
                                                  {errors.stay_address}
                                                </p>
                                              )}
                                            </div>
                                            <div className="form-group soc-add">
                                              <label className="control-label">
                                                Available room type{" "}
                                                {/* <em>*</em> */}
                                              </label>
                                              {item.room_type?.map(
                                                (item, roomIndex) => (
                                                  <>
                                                    <div className="pHightlights">
                                                      <input
                                                        placeholder="Ex: Double bedroom(Ac)"
                                                        type="text"
                                                        value={item}
                                                        onChange={(e) =>
                                                          this.handleRoomTypes(
                                                            index,
                                                            roomIndex,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      <div className="highlight-btn">
                                                        <a
                                                          className="button soc-btn"
                                                          onClick={() =>
                                                            this.handleRemove(
                                                              roomIndex,
                                                              "room_type",
                                                              index
                                                            )
                                                          }
                                                        >
                                                          -
                                                        </a>
                                                        <a
                                                          className="button soc-btn"
                                                          onClick={() =>
                                                            this.handleStayRoom(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          +
                                                        </a>
                                                      </div>
                                                    </div>
                                                    <br />
                                                  </>
                                                )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group fgp-add-img">
                                        <label className="control-label">
                                          Media {/* <em>*</em> */}
                                        </label>
                                        <p>
                                          Please add media with min resolution
                                          of 1080x1080.
                                        </p>
                                        <div className="fgp-add-row">
                                          <ul>
                                            {item?.media &&
                                              item.media
                                                .split(",")
                                                .filter(Boolean)
                                                .map(
                                                  (mediaItem, mediaIndex) => (
                                                    <li>
                                                      <div className="selected-img">
                                                        <img
                                                          src={
                                                            typeof mediaItem ===
                                                              "string" &&
                                                            mediaItem?.includes(
                                                              "https"
                                                            ) &&
                                                            mediaItem
                                                          }
                                                          onClick={() =>
                                                            this.handleOpen(
                                                              item.media,
                                                              "img"
                                                            )
                                                          }
                                                        />
                                                        <IconButton
                                                          onClick={() =>
                                                            this.handleMediaRemove(
                                                              index,
                                                              mediaIndex,
                                                              "stays"
                                                            )
                                                          }
                                                        >
                                                          <CloseIcon />
                                                        </IconButton>
                                                      </div>
                                                    </li>
                                                  )
                                                )}

                                            {item?.media === null ? (
                                              <li>
                                                <input
                                                  type="file"
                                                  className="file-input"
                                                  onChange={(e) =>
                                                    this.handleStayDetails(
                                                      index,
                                                      "media",
                                                      e
                                                    )
                                                  }
                                                />
                                                <a>
                                                  <figure>
                                                    <img
                                                      src={addplusimg}
                                                      alt="Add"
                                                    />
                                                    <figcaption>
                                                      Add image
                                                    </figcaption>
                                                  </figure>
                                                </a>
                                              </li>
                                            ) : (
                                              item?.media.split(",").length <
                                                5 && (
                                                <li>
                                                  <input
                                                    type="file"
                                                    className="file-input"
                                                    onChange={(e) =>
                                                      this.handleStayDetails(
                                                        index,
                                                        "media",
                                                        e
                                                      )
                                                    }
                                                  />
                                                  <a>
                                                    <figure>
                                                      <img
                                                        src={addplusimg}
                                                        alt="Add"
                                                      />
                                                      <figcaption>
                                                        Add image
                                                      </figcaption>
                                                    </figure>
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            )}
                        </>
                      ) : (
                        <div className="form-group">
                          <label className="control-label">
                            Day description <em style={{ color: "red" }}>*</em>
                          </label>
                          <CustomEditor
                            value={this.state.day_description}
                            onChange={this.handleEditorChange}
                          />
                          {errors.description && (
                            <p style={{ color: "red" }}>{errors.description}</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
                {this.state.openModal && (
                  <CarouselModal
                    open={this.state.openModal}
                    handleClose={this.handleClose}
                    data={this.state.carouselData}
                    type={"img"}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div id="dvLoading">
              <div className="loader triangle">
                <svg viewBox="0 0 100 100">
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="black"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
        <UnsavedChangesWarning
          message="You have unsaved changes. Are you sure you want to leave?"
          isFormEdited={this.state.isFormEdited}
        />
      </>
    );
  }
}

const UnsavedChangesWarning = ({ message, isFormEdited }) => {
  useUnsavedChangesWarning(message, isFormEdited);
  return null;
};

const mapStateTopProps = (state) => {
  var custdetailArr = Array();
  var common = Array();
  if (Object.keys(state.customerdetail).length > 0) {
    if (state.customerdetail[0].status === "ok") {
      custdetailArr = state.customerdetail[0].result_set;
      common = state.customerdetail[0].customerdetail;
    }
  }
  var uploadfiles = Array();
  if (Object.keys(state.uploadfiles).length > 0) {
    if (state.uploadfiles[0].status === "ok") {
      uploadfiles = state.uploadfiles[0];
    }
  }
  return {
    customerdetail: custdetailArr,
    common: common,
    uploadfiles: uploadfiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDetail: (params) => {
      dispatch({ type: GET_CUSTOMER_DETAIL, params });
    },
    GetUploadFiles: (formPayload) => {
      dispatch({ type: GET_UPLOAD_FILES, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Listpromo);
